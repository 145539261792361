import React, { useEffect, useState, useContext} from 'react';
import {  useNavigate, Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Input from '../../shared/components/FormElements/Input';

import { useForm } from '../../shared/hooks/form-hook';
import {VALIDATOR_REQUIRE} from '../../shared/helpers/validators';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { BetContext } from '../../shared/context/bet-context';

//no idea where this is implemented


const AddBetGame = () => {
  const auth = useContext(AuthContext);
  const bet = useContext(BetContext);

  const [formState, inputHandler] = useForm(
    {
      gameName: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const {isLoading, error, sendRequest } = useHttpClient();
  const [gameName, setGameName] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const defineGame = async () => {
      try {
        setGameName(`${auth.username} vs ${bet.opponentName}`);
      } catch (err) {}
    };
    defineGame();
  }, [auth.username, bet.opponentName]);



  const eventSubmitHandler = async event => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_HOST}/games/generatename/${formState.inputs.gamename.value}`);
      
      bet.gameName = responseData.GameName;
      navigate('/addbetgametype/');
    } catch (err) {}
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
      <React.Fragment>  
      {!isLoading && gameName && (
          <form className="event-form" onSubmit={eventSubmitHandler}>
            <h3>Place a Wager {auth.username}<br/>with a friend!</h3>
            <Link to="/"><Button variant="contained">Cancel</Button></Link>
            <Input
              id="gamename"
              element="input"
              type="text"
              label="Game Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a game name."
              onInput={inputHandler}
              initialValue={gameName}
              initialValid={false}
            />  
            <Button variant="contained" type="submit">Select a Trophy</Button>
          </form>
      )}
      {error && (
        <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
      )}
    </React.Fragment>
  );
};

export default AddBetGame;
