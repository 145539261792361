import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  dbStatus: "",
  token: null,
  player: null,
  changeDBStatus: () => {},
  setUserImage: () => {},
  setTimezone: () => {},
  login: () => {},
  logout: () => {}
});
