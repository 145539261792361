import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab'; 
import AddIcon from '@mui/icons-material/Close';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

const GameTrophy = (props) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [trophy, setTrophy] = useState();
  const gameName = useParams().gamename;
  const trophyId = useParams().trophyid;
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchTrophy = async () => {
      try {
        const trophyData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/trophies/${trophyId}`
        );
        setTrophy(trophyData.trophy)
      } catch (err) {}
    };
    fetchTrophy();
  },[trophyId, sendRequest]);

  const goBack = () => {
    navigate(-1);
  }
  
    return (
      <React.Fragment>
        {isLoading && (
          <div className="center">
            <LoadingSpinner />
          </div>
        )}

        {!isLoading && trophy && (
          <React.Fragment>
            <h5>Trophy for {gameName}</h5>
            <Grid container style={{ minWidth: 250, minHeight:300 }}>
              <Grid item xs={6}>
                <h5>{trophy.name}</h5><p/><h6>{trophy.description}</h6>
              </Grid>
              <Grid item xs={6}>
                <img  src={trophy.image} alt="Preview" />
              </Grid>
            </Grid>
            <Fab color="secondary" className="fab" size="large" aria-label="close" onClick={goBack}>
              <AddIcon />
            </Fab>
          </React.Fragment>
        )}
      </React.Fragment>
      )
    
}

export default GameTrophy;