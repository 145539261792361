import React, { useState, useContext, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'; 

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './BetPlayersHouse.css';
//shows up on bet detail page

const BetPlayersHouse = ({wager, canwager}) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  const [yesCount, setYesCount] = useState(0);
  const [noCount, setNoCount] = useState(0);
  const [yesYou, setYesYou] = useState("");
  const [noYou, setNoYou] = useState("");
  const [yesClass, setYesClass] = useState("yesNormal");
  const [noClass, setNoClass] = useState("noNormal");
  const [allowWager, setAllowWager] = useState(canwager);

  useEffect(() => {
    // check if we are within 2 hours of start time
    const timeToStart = wager.event.eventStartDate - Date.now();
    console.log(`time to start: ${timeToStart}`);
    if(timeToStart<7200000){
      setAllowWager(false);
    }

    if(wager.yesplayers!==undefined){
      setYesCount(wager.yesplayers.length);
      const you = wager.yesplayers.filter(player => player===auth.userId);
      if(you.length>0){
        setYesYou("You and ")
        setAllowWager(false);
        setYesCount(wager.yesplayers.length-1);
      }
    } else {
      wager.yesplayers = [];
    }
    
    if(wager.noplayers!==undefined){
      setNoCount(wager.noplayers.length);
      const you = wager.noplayers.filter(player => player===auth.userId);
      if(you.length>0){
        setNoYou("You and ")
        setAllowWager(false);
        setNoCount(wager.noplayers.length-1);
      } else {
        wager.noplayers = [];
      }
    }

    if(wager.arbitrated){
      setAllowWager(false);
      if(wager.arbitrationposition==="YES"){
        setYesClass("yesArbitrated");
      } else {
        setNoClass("noArbitrated");
      }
    }
    
  }, [wager, auth.userId, allowWager])

  const voteYes = async () => {
    await registerVote("yes");
  }


  const voteNo = async () => {
    await registerVote("no");
  }

  /** 
     * @async
     * @param {string} vote - The vote to register. "yes" or "no".
     * @returns {Promise<void>}
     */
    const registerVote = async (vote) => {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/vote`,
          'POST',
          JSON.stringify({
            betid: wager._id,
            vote: vote,
            player: auth.userId
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if(vote==="yes"){
          wager.yesplayers.push(auth.userId);
        } else {
          wager.noplayers.push(auth.userId);
        }
        setAllowWager(false);
      } catch (err) {
        alert(`Failed to vote: ${err.message}` );
      }
    };
  

    return (
      <React.Fragment>
          <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <div className="yes-no-block">
              <Typography  variant="h6" >
                <div align="center" class={yesClass}>           
                  <b>{yesYou}{yesCount} players</b> <br/>
                  {allowWager && (
                    <Button sx={{ color: 'white', backgroundColor: 'green' }} onClick={voteYes} variant="contained">Vote Yes</Button>
                  )}
                  
                </div>
              </Typography>

              <Typography variant="h4">
                <div class="yes-no">
                 <div class={yesClass} float="left"> <b>&nbsp;YES</b>&nbsp;&nbsp;</div>
                 <div class={noClass} float="right"><b> NO&nbsp;</b> </div>
                </div>
              </Typography>
              
              <Typography variant="h6" >
                <div align="center" class={noClass}>
                  <b>{noYou}{noCount} players</b> <br/>
                  {allowWager && (
                    <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={voteNo} variant="contained">Vote No</Button>
                  )}
                </div>
              </Typography>
            </div>
           
          </Grid>
        </Grid>
     </React.Fragment>
    );
  

  }


export default BetPlayersHouse;
