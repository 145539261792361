import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';
import {AdminList} from './components/AdminList';

const EventArbitrationList = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const [bets, setBets] = useState();
  const eventId = useParams().eventId;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/event/${eventId}`
        );

        setBets(responseData.bets);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, eventId]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && bets && (
        <AdminList wagers={bets}/>
      )}
    </React.Fragment>
  );
};

export default EventArbitrationList;
