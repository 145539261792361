import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import TrophiesList from './components/TrophiesList';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';

const Trophies = () => {
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedTrophies, setLoadedTrophies] = useState();

  useEffect(() => {
    const fetchTrophies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/trophies`
        );

        setLoadedTrophies(responseData.trophies);
      } catch (err) {}
    };
    fetchTrophies();
  }, [sendRequest]);


  return (
    <React.Fragment>
      
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {error && (
          <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
      )}
      <Link to="/admin/addtrophy"><Button variant="contained">Add Trophy</Button></Link>
      {!isLoading && loadedTrophies && <TrophiesList items={loadedTrophies} />}
    </React.Fragment>
  );
};

export default Trophies;
