import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Fab from '@mui/material/Fab'; 
import AddIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
//import Button from '@mui/material/Button';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { wagerOpponent } from '../../shared/helpers/game-formatters';

import BetItem from './BetItem';
import BetComments from './BetComments'; 
import BetAction from './BetAction';


const BetDetail = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [loadedBet, setLoadedBet] = useState();
  const [title, setTitle] = useState("");
  const betId = useParams().betId;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const betData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/${betId}`
        );
        if(betData.bet.game.house){
          setTitle(betData.bet.game.name);
        } else {
          const opponent = wagerOpponent(auth, betData.bet.creator.username, betData.bet.opponent.username);
          setTitle(`${auth.username} vs ${opponent}`);
        }
         setLoadedBet(betData.bet);
      } catch (err) {}
    };
    fetchEvent();
  },[betId, sendRequest, auth],);

  const goBack = () => {
    navigate(-1);
  }

  
    return (
      <React.Fragment>
      {isLoading && (
       <div className="center">
         <LoadingSpinner />
       </div>
      )}

     {!isLoading && loadedBet &&(
      <React.Fragment>  
        <div>
         <Fab color="secondary"  size="medium" aria-label="close" onClick={goBack}
          style={{position: 'fixed', right: '5px', top: '100px'}}>
          <AddIcon />
         </Fab>
        </div>

          <Grid container style={{ minWidth: 300, maxWidth:550 }}>
            <Grid item xs={7}>
             <Typography Typography color = 'black'>             
                <div style={{fontSize:30, float: 'left'}}>{title}</div> 
             </Typography>
            </Grid>
       
          
          <BetComments comments={loadedBet.comments}/>
          <BetItem
            key={loadedBet.id}
            id={loadedBet._id}
            wager={loadedBet}
            canwager={true}
            topicTitle={loadedBet.event.topic.title}
            topicArea={loadedBet.event.topic.area}
            event={loadedBet.event}
            closedate={loadedBet.event.wagerCloseDate}
            text={loadedBet.text}
            status={loadedBet.status}
            creatorname={loadedBet.creator.username}
            creatorimage={loadedBet.creator.image}
            opponentname={loadedBet.opponent.username}
            opponentimage={loadedBet.opponent.image}
            initiator={loadedBet.initiatingplayer}
            initiatorPosition={loadedBet.initiatingposition}
            arbitrated={loadedBet.arbitrated}
            arbitrationPosition={loadedBet.arbitrationposition}
            arbitrationResults={loadedBet.arbitrationtext}
          />

          {!loadedBet.game.house && (
            <BetAction 
              id={loadedBet.id}
              wager={loadedBet}
              status={loadedBet.status}
              isCreator={auth.username===loadedBet.creator.username}
              isInitiator={auth.userId===loadedBet.initiatingplayer}
              initiatorPosition={loadedBet.initiatingposition}
            />
          )}
             </Grid>

      </React.Fragment>
     )}


     </React.Fragment>
    );    
}

export default BetDetail