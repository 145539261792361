import React, {useState, useEffect} from 'react';
import {  useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import GameHeader from './GameHeader';

//page controlling the wager's on the home page
const increment = 3;

const GamesList = props => {
  const [gamesSize, setGamesSize] = useState(increment);
  const [games] = useState(props.items);
  const [displayGames, setDisplayGames] = useState();
  const [showMore,setShowMore] = useState(false);
  const [showLess,setShowLess] = useState(false);
  const navigate = useNavigate();
  
   // reset the displayed comments when comments or size changes
   useEffect(() => {
    setShowMore(props.items.length > gamesSize);
    setShowLess(gamesSize > increment)
    setDisplayGames( props.items.slice(0,gamesSize) );
  }, [gamesSize, games, props.items]); 

  const loadMore = () =>{
    setGamesSize(increment+gamesSize);
  }
  const loadLess = () =>{
    setGamesSize(increment);
  }

  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h5>Make a wager with a friend by starting a new game</h5>
        </Card>
      </div>
    );
  }
  
 
  return (
    <React.Fragment>
    {displayGames && (
     
      <ul >
        {displayGames.map(game => (
          <li key={game.id}> 
            <div onClick={() => navigate(`/viewgame/${game.id}`)}>          
              <GameHeader game={game}/> 
            </div>         
          </li>
        ))}
      </ul>
      
    )}
    {showMore && <Button sx={{float: 'left'}} variant="contained" onClick={loadMore}> Show More </Button>}
    {showLess && <Button sx={{float: 'right'}} variant="contained" onClick={loadLess}> Show Less </Button>}
   
     </React.Fragment>
  );
  
};

export default GamesList;
