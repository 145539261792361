import { useState, useContext } from 'react';
import { AuthContext } from '../../shared/context/auth-context';

export const useSSEEvent = () => {

  const [error, setError] = useState();
  const [eventSource, setEventSource] = useState(null);
  const auth=useContext(AuthContext);
  
  
  let commentBetId = null;
  let onCommentChange = null;
  let onHomeChange = null;

  const clearError = () => {
    setError(null);
  };

  const startSSE = () => {
    sendDispatch();
    commentBetId = null;
  }

  const stopSSE = (from) => {
    console.log(`SSE closing from ${from}`);
    if(eventSource){
      eventSource.close();
    }
  }

  const setCommentChange = (id,callback) => {
    onCommentChange = callback;
    commentBetId = id;
  }

  const setHomeChange = (callback) => {
    onHomeChange = callback;
  }

  function signalCommentChange(id){
    console.log(`received sse ${id}==${commentBetId}`);
    if(id===commentBetId){
      onCommentChange();
    }
  }

  function signalHomeChange(fullMessage){
    console.log(`signalHomeChange auth=${auth.userId}=${fullMessage.target}`)
    if(onHomeChange !== null && auth.userId===fullMessage.target){
      onHomeChange(fullMessage.text);
    }
  }

  function sendDispatch(){
    
    if(!eventSource || eventSource.readyState===2){
      console.log(`starting sse`);
      const mysource = new EventSource(process.env.REACT_APP_STREAMING);
      setEventSource(mysource); 
      mysource.onmessage = function logEvents(event) {  
        try{
          const parsedMessage = JSON.parse(event.data);
          console.log(`received sse ${JSON.stringify(parsedMessage)}`);
          if(parsedMessage.type==="comment"){
            signalCommentChange(parsedMessage.text);
          } else {
            signalHomeChange(parsedMessage);
          } 
        } catch( e){
          console.log(`failed to parse sse ${e.msg}`);
        }
        
      }
    }
  }


  return { error, startSSE, stopSSE, setCommentChange, setHomeChange, clearError };
};
