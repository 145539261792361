import React from 'react';

import { CardActions } from '@mui/material';

import './BetStyles.css';

const BetFooter = props => {
  let commentUsername="";
  const commentLine = resolveComments();

  if(commentLine.length > 0){
    return (  
        <CardActions className="bet-item-comments-block" >
            <><span className="username"> {commentUsername}:</span> {commentLine}</>
        </CardActions>
    );
    } else {
        return "";
    }

  function resolveComments() {
    let results = "";
    if(props.comments.length > 0){
      commentUsername =  props.comments[0].creatorname
      results = props.comments[0].message;
    } 
    return results;
  }
};

export default BetFooter;