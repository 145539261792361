import React from 'react';

import TrophyItem from './TrophyItem';
import Card from '../../shared/components/UIElements/Card';
import './EventsList.css';

const TrophiesList = props => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No trophies found.</h2>
        </Card>
      </div>
    );
  }

  return (
    <ul className="events-list">
      {props.items.map(trophy => (
        <TrophyItem key={trophy.id} item = {trophy} />
      ))}
    </ul>
  );
};

export default TrophiesList;

