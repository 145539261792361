import { createContext } from 'react';

export const BetContext = createContext({
  creator: null,
  opponent:null, 
  yesPlayer: null,
  noPlayer: null,
  gameName:"",
  gameId:null,
  gameHouse:false,
  trophyId:null,
  trophyName:"",
  trophyImage:null,
  topicId: null,
  topicName: "",
  topicArea: "",
  eventId: null,
  eventTitle: "",
  eventDetails: null,
  wagerCloseDate: "",
  eventStartDate: "",
  eventEndDate: "",
  betText: ""
});
