import React , { useContext }from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import { BetContext } from '../../shared/context/bet-context';
import './AddWagerTitle.css'


const AddWagerTitle = () => {

  const bet = useContext(BetContext);
  const title = bet.gameHouse?bet.gameName:`${bet.yesPlayer.username} vs ${bet.noPlayer.username}`
  return (
       <React.Fragment>

        <Grid container>
          <Grid item xs={12}>
            <Typography color="black" style={{ fontWeight: 900, paddingLeft: '10px' }} variant="h4">
              {title}
            </Typography>
          </Grid>
        </Grid>

      </React.Fragment>
  );
  
  
}

export default AddWagerTitle;
