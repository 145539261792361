import React, { useEffect, useState, useContext} from 'react';
import {  useNavigate, Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { BetContext } from '../../shared/context/bet-context';
import "../../admin/Form.css"

//not using this either

const Events = () => {
  const bet = useContext(BetContext);
  
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedTrophies, setLoadedTrophies] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrophies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/trophies`
        );
        
        setLoadedTrophies(responseData.trophies);
      } catch (err) {}
    };
    fetchTrophies();
  }, [sendRequest]);

  let selectedTrophy = null;
  const handleSelect=(e)=>{
    if(e.target.selectedIndex>=0){
      selectedTrophy = loadedTrophies[e.target.selectedIndex];
    }
  }

  let createWager = true;
  const stopWager= (e)=>{
    createWager = false;
  }

  const eventSubmitHandler = event => {
    event.preventDefault();
    
    try {
      if (createWager){
        bet.trophyId = selectedTrophy.id;
        bet.trophyName = selectedTrophy.name;
        bet.trophyImage = selectedTrophy.image;
        navigate('/addbettopic/');
      } else {
        navigate('/');
      }
      
    } catch (err) {}
  };


  return (
    <React.Fragment>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
        
      {!isLoading && loadedTrophies && (
        <form className="form" onSubmit={eventSubmitHandler}>
          <h5>
            Select a Trophy for your Game {bet.gameName} with {bet.opponentName}<br/><br/>
            This game is Head-to-Head. It is only between you and {bet.opponentName}<br/>
            It contains a single wager, the winner wins the Game. 
          </h5>
          <Link to="/"><Button variant="contained">Cancel</Button></Link>
          <select  className="browser-default" autoFocus={true} onChange={handleSelect} onBlur={handleSelect}>
            {loadedTrophies.map(trophy => (
              <option value={trophy._id} key={trophy._id}>{trophy.name}</option>
            ))}
          </select>
          <div>
          <Button style={{float: 'left'}} variant="contained" type="submit" onClick={stopWager}>Cancel</Button>
          
          <Button style={{float: 'right'}} variant="contained" type="submit">Continue to Create a Wager</Button>
          </div>
        </form>
      )}
      {error && (
        <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
      )}

    </React.Fragment>
  );
};

export default Events;
