import React , {useEffect, useContext, useState} from 'react';
import {  useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import BetItem from './BetItem';
import { BetContext } from '../../shared/context/bet-context';
import { AuthContext } from '../../shared/context/auth-context';
import Card from '../../shared/components/UIElements/Card';
import { setOpponentInformation, setWagerDefaults } from '../../shared/helpers/utilities';
import './BetsList.css';

const BetsList = ({bets, game}) => {
  const bet = useContext(BetContext);
  const auth = useContext(AuthContext);
  const [displayWagers, setDisplayWagers] = useState(bets);
  const [closedWagers, setClosedWagers] = useState([]);
  const [displayClosedWagers, setDisplayClosedWagers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if(game.house){

      const wagers = [];
      const oldWagers = [];
      let  eventName = null;
      bets.forEach( aBet => {
        if(aBet.arbitrated){
          if(!eventName){
            eventName = aBet.event.name;
          }
          if (aBet.event.name === eventName){
            wagers.push(aBet);
          } else {
            oldWagers.push(aBet);
          }
      // use event id to only add those wagers to the list
        } else {
          wagers.push(aBet);
        }
      })
      setDisplayWagers(wagers.reverse());
      setClosedWagers(oldWagers.reverse());
     
    }
  }, [game, bets]);

  const showOldWagers = () => {
    setDisplayClosedWagers(closedWagers.reverse());
  }

  const addWager = async () =>{
    const yesPlayer = {
      id: auth.userId,
      username: auth.username,
      image: auth.userImage
    }
    bet.yesPlayer = yesPlayer;
    bet.creator = auth.userId;
    bet.betText = "";
    bet.topicId=null;
    const opponent = game.creator.username===auth.username?game.opponent:game.creator;
    setOpponentInformation(bet, opponent);
    await setWagerDefaults(auth, bet);  
    bet.gameId = game._id;
    bet.gameName = game.name;
    
    navigate('/addbettopic/');
  }

  if (displayWagers.length === 0 && !game.house) {
    return (
      <div className="center">
        <Card>
          <h4>No wagers found.</h4>
          <Button onClick={addWager} variant="contained" color='secondary'>Add Wager</Button>
        </Card>
      </div>
    );
  }
  
  return (
    <React.Fragment>
    <ul className="bets-list">
      {displayWagers.map(bet => (
        <li key={bet.id}>
        <div onClick={() => navigate(`/viewbet/${bet._id}`)}>
        <BetItem
          key={bet.id}
          id={bet._id}
          wager={bet}
          canwager={false}
          topicTitle={bet.event.topic.title}
          topicArea={bet.event.topic.area}
          event={bet.event}
          closedate={bet.event.wagerCloseDate}
          text={bet.text}
          status={bet.status}
          creatorname={bet.creator.username}
          creatorimage={bet.creator.image}
          opponentname={bet.opponent.username}
          opponentimage={bet.opponent.image}
          initiator={bet.initiatingplayer}
          initiatorPosition={bet.initiatingposition}
          arbitrated={bet.arbitrated}
          arbitrationPosition={bet.arbitrationposition}
          arbitrationResults={bet.arbitrationtext}
        />
        </div>
        </li>
      ))}
    </ul>

      {closedWagers.length !== 0 && displayClosedWagers.length === 0 && (
        <Card>
          <Button onClick={showOldWagers} variant="contained" color='secondary'>Show Older Wagers</Button>
        </Card>
      )}

      {displayClosedWagers && displayClosedWagers.length !== 0 && (
        <ul className="bets-list">
          {displayClosedWagers.map(bet => (
            <li key={bet.id}>
            <div onClick={() => navigate(`/viewbet/${bet._id}`)}>
            <BetItem
              key={bet.id}
              id={bet._id}
              wager={bet}
              topicTitle={bet.event.topic.title}
              topicArea={bet.event.topic.area}
              event={bet.event}
              closedate={bet.event.wagerCloseDate}
              text={bet.text}
              status={bet.status}
              creatorname={bet.creator.username}
              creatorimage={bet.creator.image}
              opponentname={bet.opponent.username}
              opponentimage={bet.opponent.image}
              initiator={bet.initiatingplayer}
              initiatorPosition={bet.initiatingposition}
              arbitrated={bet.arbitrated}
              arbitrationPosition={bet.arbitrationposition}
              arbitrationResults={bet.arbitrationtext}
            />
            </div>
            </li>
          ))}
      </ul>
      )}

    </React.Fragment>
  );
};

export default BetsList;
