import React, { useEffect, useState, useContext } from 'react';

import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import './main.css';

const PeoplePage = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const [displayedUsers, setDisplayedUsers] = useState(); 
  const [emailSearch, setEmailSearch] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/users`
        );
        const filteredUsers = responseData.users.filter(user => user.id !== auth.userId);
        setLoadedUsers(filteredUsers);
        setDisplayedUsers(filteredUsers);
      } catch (err) {}
    };
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const emailChange = async event => {
    setEmailSearch(event.target.value);
    setDisplayedUsers(loadedUsers.filter(
          user => (user.username.includes(event.target.value) || user.email.includes(event.target.value))));
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && displayedUsers && (
        <React.Fragment>
          <div>
            Search for friends by email:
            <input
              id="newfriend"
              type="text"
              className="background-yellow"
              onChange={emailChange}
              initialValue="test email"
            />
          </div>
          <UsersList items={displayedUsers} email={emailSearch}/>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PeoplePage;
