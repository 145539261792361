import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import './EventItem.css';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const UnarbitratedEventItem = props => {
  const { isLoading, sendRequest, error } = useHttpClient();
  const [betCount, setBetCount] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/event/${props.id}`
        );
        setBetCount(responseData.bets.length);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, props.id]);

  const closeEvent = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/events/close/${props.id}`,
        'POST',"{}",
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      navigate(-1);
      
    } catch (err) {}
  };

  return (
    <React.Fragment>
    { !isLoading && (
      <li className="event-item">
          <div className="event-item__info">
            <h2>
              {props.topic}:{props.name}  <br/>
              close date:{props.closedate} not closed
              number of wagers: {betCount}
            </h2>

            {betCount>0 && (
              <React.Fragment>
                <span>
                  <Button variant="contained" onClick={closeEvent}>Close Event</Button>
                  <Link to={`/admin/eventsbets/${props.id}`}><Button variant="contained">View Wagers</Button></Link>
                </span>
                </React.Fragment>
            )}

            {!betCount && (
              <React.Fragment>
                <hr/>
                <Link to={`/admin/eventremove/${props.id}`}><Button variant="contained">Remove Event</Button></Link>
              </React.Fragment>
            )}

            {error && (
              <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
            )}
            
          </div>
    </li>
    )}
    </React.Fragment>
  );
};

export default UnarbitratedEventItem;
