import dayjs from 'dayjs';

export const timeFormatter = (paramDate) => {
    let results = "";
    const seconds = Math.abs((dayjs() - paramDate)/1000);
    
    if (seconds <= 60){
      results = Math.floor(seconds+1) + "s"
    } else {
      let hours = Math.floor(seconds / 3600);
      if (hours > 48){
        results = dayjs(paramDate).format('MM/DD/YYYY');
      } else if (hours > 0){
        results = `${hours} ${hours===1? "hr":"hrs"}`;

      } else {
        results = `${Math.floor(seconds/60)} min`;
      }
    }
    
    return `${results}`;
}

export const timeFormatterWithText = (prefix, paramDate, suffix) => {
  const hours = Math.abs((dayjs() - paramDate)/(1000*3600));  //not happy about this duplication
  const preposition = hours > 48? "on":"in";
  return `${prefix} ${preposition} ${timeFormatter(paramDate)} ${suffix}`;
}

export const commentTruncator = (width, comment) => {
    let results = comment;
    if(comment.length > width){
       let arrayString = comment.split(" ");
       results = "";
       for (const item of arrayString){
        if(width >= (results.length + item.length)){
            results += ` ${item}`;
        } else {
            results += "...";
            break;
        }
       }
    }
    return results;
}

export const truncateByWords = (count, comment) => {
  let arrayString = comment.split(" ");
  let results = "";
  count = count < arrayString.length? count : arrayString.length;
  let suffix = count===arrayString.length? "":"...";
  for (let i = 0; i < count; i++) {
    results += " " + arrayString[i];
  }

  results += suffix;
  return results;
}