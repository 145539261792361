import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc')
var tz = require('dayjs/plugin/timezone')
var  advanced = require( "dayjs/plugin/advancedFormat")
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(advanced)


export const BuildWagerInfo = async (auth, bet, user,  gameName) => {
  
  try {
    bet.creator = auth.userId;
     setOpponentInformation(bet,user);
     bet.gameName = gameName;
     setDefaultEvent(bet, auth.timezone);
   
   } catch (err) {
        console.log(`defaulting bet error ${err}`);
   }
}

export const setWagerDefaults = async (auth, bet) =>{
  try {
   // Default Trophy for games
    const responseData = await fetch(
      `${process.env.REACT_APP_API_HOST}/trophies/default`
    );
    const trophyData = await responseData.json();
    if (trophyData){
      bet.trophyId = trophyData.trophy._id;
      bet.trophyImage = trophyData.trophy.image;
      bet.trophyName = trophyData.trophy.name;
    }

    setDefaultEvent(bet, auth.timezone);
  
  } catch (err) {
    console.log(`defaulting bet error ${err}`);
  }

}

export const setOpponentInformation = (bet, myUser) =>{
    bet.opponent = myUser.id;
    bet.noPlayer = myUser;
  }

export const setDefaultEvent =  ( bet, timezone) => {
  
  const eventDate = dayjs().utc().add(24,'h').tz(getTimezoneParameter(timezone));
  bet.eventTitle = eventDate.format('MMM DD') + " Event";

  setEventDates(bet, eventDate);
}

export const setEventDates =  (bet, startDate) => {
  
  bet.eventStartDate = startDate.add(24,'h');
  bet.eventEndDate = startDate.add(48,'h');
  bet.wagerCloseDate = calcWagerCloseDate(bet.eventEndDate);
  }
  
export const setEventCloseDate =  (bet, closeDate) => {
    bet.eventEndDate = closeDate;
    bet.wagerCloseDate = calcWagerCloseDate(bet.eventEndDate);
}

const calcWagerCloseDate =  (eventEndDate) => {
  let closeDate;
  const now = dayjs();
  const diff = eventEndDate.diff(now, 'h');
  
  if (diff <= 1){
    closeDate = now.add(15,'m');
  } else if (diff < 6) {
    closeDate = eventEndDate.subtract(30,'m');
  } else if (diff < 168) {  //one week
    closeDate = eventEndDate.subtract(5,'h');
  } else {
    closeDate = now.add(5,'d');
  }
  
  return closeDate;
}

export const getTimezoneParameter = (timezone) => {

    if(timezone==="PST") return "America/Los_Angeles";
    if(timezone==="CST") return "America/Chicago";
    if(timezone==="MST") return "America/Denver";
    if(timezone==="EST") return "America/New_York";

    return "America/New_York"
}