import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Input from '../shared/components/FormElements/Input';
import Card from '../shared/components/UIElements/Card';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_FUTURE_DATE
} from '../shared/helpers/validators';
import { useForm } from '../shared/hooks/form-hook';
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import './EventForm.css';

const AddEvent = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, newError, sendRequest } = useHttpClient();
  const [loadedTopic, setLoadedTopic] = useState();
  const [todayDateFormatted] = useState(dayjs().format('MM/DD/YYYY HH:mm'));
  const topicId = useParams().topicId;
  const navigate = useNavigate();

  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: '',
        isValid: false
      },
      topic: {
        value: '',
        isValid: false
      },
      wagerCloseDate: {
        value: '',
        isValid: false
      },
      eventStartDate: {
        value: '',
        isValid: false
      },
      eventCloseDate: {
        value: '',
        isValid: false
      }
    },
    false
  );

  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/topics/${topicId}`
        );
        setLoadedTopic(responseData.topic);
        setFormData(
          {
            topic: {
              value: responseData.topic.title,
              isValid: true
            }
          },
          true
        );
      } catch (err) {}
    };
    fetchTopic();
  }, [sendRequest, topicId, setFormData]);


  const eventSubmitHandler = async event => {
      event.preventDefault();
    
      const wagerCloseDate= dayjs(`${formState.inputs.wagerCloseDate.value}`).valueOf();
      const eventStartDate= dayjs(`${formState.inputs.eventStartDate.value}`).valueOf();
      const eventCloseDate= dayjs(`${formState.inputs.eventCloseDate.value}`).valueOf();
     
      if( wagerCloseDate > eventStartDate){
        newError("Event Start date must be after Wager Close date");
      } else {
        try {
          var outstring = JSON.stringify({
            name: formState.inputs.name.value,
            wagerCloseDate: wagerCloseDate,
            eventStartDate: eventStartDate,
            eventCloseDate: eventCloseDate,
            topic: `${topicId}`
          });
          await sendRequest(
            `${process.env.REACT_APP_API_HOST}/events/add`,
            'POST',
            `${outstring}`,
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          navigate('/admin/topics/' + topicId);
        } catch (err) {}
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedTopic && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find topic!</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
    
      {!isLoading && loadedTopic && (
        <form className="event-form" onSubmit={eventSubmitHandler}>
          <Input
            label="Topic"
            onInput={inputHandler}
            initialValue={loadedTopic.title}
            initialValid={true}
          />
          <Input
            id="name"
            element="input"
            type="text"
            label="name"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter an event name."
            onInput={inputHandler}
            initialValue=""
            initialValid={false}
          />
          <Input
            id="wagerCloseDate"
            element="input"
            label="Wager Close Date"
            validators={[VALIDATOR_FUTURE_DATE()]}
            errorText="Please enter a future date (MM/DD/YYYY HH:MM)."
            onInput={inputHandler}
            initialValue={todayDateFormatted}
            initialValid={true}

          />

          <Input
            id="eventStartDate"
            element="input"
            label="Event Start Date"
            validators={[VALIDATOR_FUTURE_DATE()]}
            errorText="Please enter a future date (MM/DD/YYYY HH:MM)."
            onInput={inputHandler}
            initialValue={todayDateFormatted}
            initialValid={true}
          />

          <Input
            id="eventCloseDate"
            element="input"
            label="Event Close Date"
            validators={[VALIDATOR_FUTURE_DATE()]}
            errorText="Please enter a future date (MM/DD/YYYY HH:MM)."
            onInput={inputHandler}
            initialValue={todayDateFormatted}
            initialValid={true}
          />
          
          <Button variant="contained" type="submit" disabled={!formState.isValid}>
            Add Event
          </Button>
        </form>
        
      )}
      {error && (
          <Alert severity="error"><AlertTitle>Input Error</AlertTitle>{error}</Alert>
      )}
    </React.Fragment>
  );
};

export default AddEvent;
