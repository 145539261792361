import React, { useContext, useState } from 'react';
import {  useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button'; 
import Grid from '@mui/material/Grid';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { wagerDeclined, wagerCanceled, wagerInvited, wagerInplay, 
        wagerJudge, wagerArbInitiated, wagerSelfArbitrated, wagerContested} from '../../wagerStates';

const BetAction = props => {
  const [position, setPosition] = useState();
  const [validationMessage, setValidationMessage] = useState();
  if(position===undefined && props.initiatorPosition!==undefined){
    setPosition( props.initiatorPosition.toLowerCase()==="win"?"Lost":"Won");
  }
  const { sendRequest, error } = useHttpClient();
  const auth=useContext(AuthContext);
  const navigate = useNavigate();

  const acceptWager = async event => {
    event.preventDefault();
    await changeWagerStatus(wagerInplay);
  }
  const declineWager = async event => {
    event.preventDefault();
    await changeWagerStatus(wagerDeclined);
  }
  const cancelWager = async event => {
    event.preventDefault();
    await changeWagerStatus(wagerCanceled);
  }
  const claimWin = async () => {
    await changeWagerStatus(wagerJudge, "win");
  }
  const claimValidate = () => {
    if(validationMessage){
      changeToValidate();
    }
  }
  const admitLoss = async () => {
    await changeWagerStatus(wagerJudge, "loss");
  }
  const selfArbitration = async() => {
    await changeWagerStatus(wagerSelfArbitrated, position);
  }
  const contested = async () => {
    await changeWagerStatus(wagerContested);
  }

  const handleMessageChange = event =>{
    setValidationMessage(event.target.value);
  }

  async function changeToValidate(){
    try {
        var outstring = JSON.stringify({
          betid: `${props.id}`,
          player: `${auth.userId}`,
          message: `${validationMessage}`
        });
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/changebettovalidate`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
    } catch (err) {}
    navigate(-1);
  }

  async function changeWagerStatus(action, position){
   
    try {
        var outstring = JSON.stringify({
          betid: `${props.id}`,
          action: `${action}`,
          player: `${auth.userId}`,
          position: `${position}`
        });

        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/changebetstatus`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
    } catch (err) {
      console.log(`error changing bet status: ${err}`);
    }
    navigate(-1);
  }

  const sendMessage = async event => {
    event.preventDefault();
    try {
      if(validationMessage){
        let annotedMessage = `[NOTE TO JUDGE SNACKS] ${validationMessage}`
        var outstring = JSON.stringify({
          gameid: `${props.wager.game}`,
          creator: `${auth.userId}`,
          message: `${annotedMessage}`
        });
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/games/addcomment`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
      }
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <div className="action-block">
           {props.status===wagerInvited && !props.isCreator && (
            <React.Fragment>
              <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={acceptWager} variant="contained">
                Accept</Button>
              {"     "}
              <Button onClick={declineWager} variant="outlined">Decline</Button>
            </React.Fragment>
          )} 
          {props.status===wagerInvited && props.isCreator && (
            <React.Fragment>
              <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={cancelWager} variant="contained">
                Cancel</Button>
            </React.Fragment>
          )} 
          
          {props.status===wagerJudge && !props.isInitiator && (
            <React.Fragment>
              <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={selfArbitration} variant="contained">
                Agree I {position}</Button>
                {"     "}
              <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={contested} variant="contained">
                Disagree</Button>  
            </React.Fragment>
          )}  
          {error && (
            <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
          )}
      </div>
      
      {(props.status===wagerInplay || props.status===wagerArbInitiated)  && (
        <Grid container direction="row" justify="left" alignItems="left" sx={{ width:'600px'}}>
          <Grid item xs={4.5}>
            <Button sx={{ color: 'white', backgroundColor: 'green' }} onClick={claimWin} variant="contained">
              Claim Win!</Button>
            {"   "}                
            <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={admitLoss} variant="contained">
              Admit Loss</Button>
          </Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={12} style={{ float: 'left', textAlign: 'left'}} sx={{ mt: 2 }}>
            <Button sx={{ color: 'white', backgroundColor: 'green' }} onClick={claimValidate} variant="contained">
              Validate</Button>
            <input
                id="bettext"
                style={{ width: '400px' }}
                type="text"
                color='blue'
                onChange={handleMessageChange}
                placeholder="Enter a message for the validator"             
            />
              
          </Grid>
        </Grid>
      )} 

      {props.status===wagerContested && (
        <Grid container direction="row" justify="left" alignItems="left" sx={{ width:'600px'}}>
          <Grid item xs={12} style={{ float: 'left', textAlign: 'left'}}>
            <Button sx={{ color: 'white', backgroundColor: 'green' }} onClick={sendMessage} variant="contained">
              Comment</Button>
            <input
                id="bettext"
                style={{ width: '400px' }}
                type="text"
                color='blue'
                onChange={handleMessageChange}
                placeholder=" SEND MSG TO TO JUDGE SNACKS"            
            />
              
          </Grid>
        </Grid>
      )}     
    </React.Fragment>
  );
};

export default BetAction;
