import React, {useContext}from 'react';
import dayjs from 'dayjs';

import { AuthContext } from '../../shared/context/auth-context';
import { getTimezoneParameter } from '../../shared/helpers/utilities'


import EventItem from './EventItem';

import Card from '../../shared/components/UIElements/Card';

import './EventsList.css';


const EventsList = props => {
  const auth = useContext(AuthContext);
  
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No events found.</h2>
        </Card>
      </div>
    );
  }

  function processDate(date) {

    return dayjs(date).utc().tz(getTimezoneParameter(auth.timezone)).format('MM/DD/YYYY HH:mm z');
  
  }

  return (

    <ul className="events-list">
      {props.items.map(event => (
        <EventItem
          key={event.id}
          id={event.id}
          topic={event.topic.title}
          name={event.name}
          closedate={processDate(event.wagerCloseDate)}
          startdate={processDate(event.eventStartDate)}
          enddate={processDate(event.eventCloseDate)}
          arbitrated={event.arbitrated}
        />
      ))}
    </ul>

  );

};


export default EventsList;



 