import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import './Item.css';

const TrophyItem = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const [gameCount, setGameCount] = useState();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/games/trophy/${props.item.id}`
        );

        setGameCount(responseData.games.length);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, props.item.id]);

  const removeTrophyHandler = async () =>{
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/trophies/remove/${props.item.id}`,
        'POST',"{}",
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      navigate(-1);
      
    } catch (err) {}
  }

  return (
    <React.Fragment>
      
      {!isLoading && (
    
        <li className="item" >
              <div className="item__info">
                <h2>
                  {props.item.name}  <br/>
                  Description:{props.item.description} <br/>
                </h2>
                <img className="item__image" src={props.item.image} alt="Preview" />
                <hr/>
                {!gameCount && (
                  <React.Fragment>
                    <Button variant="contained" onClick={removeTrophyHandler}>Remove Trophy</Button>
                  </React.Fragment>
                )}
                {gameCount && (
                  <h2>
                    Trophy is used in {gameCount} games
                  </h2>
                )}
              </div>
        </li>

      )}

    </React.Fragment>
  );
};

export default TrophyItem;
