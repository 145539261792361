import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH
} from '../../shared/helpers/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Register.css';

const InvitedPlayer = () => {
  const auth = useContext(AuthContext);
  const userId = useParams().id;
  const [loadedUser, setLoadedUser] = useState();
  const {isLoading, error, sendRequest} = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      email:{
        value:'',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      },
      username: {
        value: '',
        isValid: false
      }
    },
    false
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/users/id/${userId}`
        );
        setLoadedUser(responseData.user);
      } catch (err) {}
    };
    fetchUser();
  }, [sendRequest, userId, setLoadedUser]);

  const authSubmitHandler = async event => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/invite`,
        'POST',
        JSON.stringify({
          username: formState.inputs.name.value.trim(),
          email: formState.inputs.email.value.trim(),
          password: formState.inputs.password.value.trim(),
          id: `${userId}`
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );

      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/games/updateinvitedplayer`,
        'POST',
        JSON.stringify({
          username: responseData.username,
          email: loadedUser.email
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + responseData.token
        }
      );
      
      auth.login(responseData.player, 
        responseData.token);
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!isLoading && !loadedUser && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find player!</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Card className="registration">

        {!isLoading && loadedUser && (
          <React.Fragment>
            <h4>Welcome to Casual Wager!<br/>{loadedUser.email}<br/>Fill in a username and password, re-enter an email of your choice</h4>
            <hr />
            <form onSubmit={authSubmitHandler}>
              <Input
                element="input"
                id="name"
                type="text"
                label="Your Username"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Please enter a username."
                onInput={inputHandler}
              />
              <Input
                element="input"
                id="email"
                type="email"
                label="E-Mail"
                initialValue=""
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address."
                onInput={inputHandler}
              />
              <Input
                element="input"
                id="password"
                type="password"
                label="Password"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Please enter a valid password, at least 6 characters."
                onInput={inputHandler}
              />
              <Link to="/"><Button style={{float: 'left'}} variant="contained">Cancel</Button></Link>
              <Button style={{float: 'right'}} variant="contained" type="submit">Update</Button>
            </form>
            {error && (
              <Alert severity="error"><AlertTitle>Registration Error</AlertTitle>{error}</Alert>
            )}
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
};

export default InvitedPlayer;
