import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { red } from '@mui/material/colors'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import BetHeader from '../../user/components/BetHeader';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const AdminList = props => {
  const { sendRequest, error } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState("");

  const goBack = () => {
    navigate(-1);
  }

  const handleMessageChange = event =>{
    setValidationMessage(event.target.value);
  }

  async function validate(id){
    try {
        var outstring = JSON.stringify({
          betid: `${id}`,
          player: `${auth.userId}`
        });
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/validatebet`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
    } catch (err) {}
    navigate("/");
  }

  async function reject(id){
    try {
      if(!validationMessage){
        return;
      }
      var outstring = JSON.stringify({
        betid: `${id}`,
        player: `${auth.userId}`,
        message: `${validationMessage}`
      });
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/bets/rejectbet`,
        'POST',
        `${outstring}`,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
    } catch (err) {}
    navigate("/");
  }

  return (
    <React.Fragment>
        <Button onClick={goBack} variant="contained">Back</Button>
        <ul className="bets-list">
          {props.wagers.map (wager => (
             <Card 
             square={true}
             elevation={0} 
             className="bet-item bet-item-color"
             color={ red }
           >
            <BetHeader
              key={wager.id}
              id={wager.id}
              wager={wager}
              topicTitle={wager.event.topic.title}
              status={wager.status}
              event={wager.event}
              text={wager.text}
              creator={wager.creator.username}
              opponent={wager.opponent.username}
              arbitrated={wager.arbitrated}
            />
            
            {props.type!=="validation" && !wager.arbitrated && (
              <Link to={`/admin/arbitratebet/${wager.id}`}><Button variant="contained">Arbitrate </Button></Link>
            )}
            
            {props.type==="validation" &&  (
              <Grid container direction="row" justify="left" alignItems="left" sx={{ width:'600px'}}>
                <Grid item xs={4.5}>
                  <Button sx={{ color: 'white', backgroundColor: 'green' }} onClick={()=>{validate(wager.id)}} variant="contained">
                    Validate</Button>
                  {"  "}                
                  {/* <Link to={`/admin/rewritebet/${wager.id}`}> */}
                    <Button onClick={()=>{navigate(`/admin/rewritebet/${wager.id}`)}} 
                      sx={{ color: 'white', backgroundColor: 'red' }}  variant="contained">
                      ReWrite
                    </Button>
                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={12} style={{ float: 'left', textAlign: 'left'}} sx={{ mt: 2 }}>
                  <Button sx={{ color: 'white', backgroundColor: 'green' }} 
                    onClick={()=>{reject(wager.id)}} variant="contained">
                    Reject
                  </Button>
                  <input
                      id="bettext"
                      style={{ width: '400px' }}
                      type="text"
                      color='blue'
                      onChange={handleMessageChange}
                      placeholder="Enter a message for the validator"
                  />
                 
                </Grid>
              </Grid>
            )} 

            {error && (
              <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
            )}
            </Card>
           
          ))}
          
        </ul>

    </React.Fragment>

  );
};

export {AdminList};
