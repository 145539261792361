import React, { useEffect, useState, useContext} from 'react';
import {  useNavigate, Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import  {Avatar}  from '@mui/material';
import Grid from '@mui/material/Grid';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import "../../admin/Form.css"
const increment = 5;

const Events = () => {
  const auth = useContext(AuthContext);
  
  const {isLoading, error, sendRequest } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const [userSize, setUserSize] = useState(increment);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [displayUsers, setDisplayUsers] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/users/friends/${auth.userId}`
        );
        setLoadedUsers(responseData.users);
        setShowMore(responseData.users.size > increment);
        setShowLess(responseData.users.size > increment)
        setDisplayUsers( responseData.users.slice(0,increment));
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth.userId]);

  const loadMore = () =>{
    setUserSize(increment+userSize);
  }
  const loadLess = () =>{
    setUserSize(increment);
  }

  useEffect(() => {
    if(loadedUsers){
      setShowMore(loadedUsers.length > userSize);
      setShowLess(userSize > increment)
      setDisplayUsers( loadedUsers.slice(0, userSize) );
    }
    
  }, [userSize, loadedUsers]); 

  const eventSubmitHandler =  async (event, userId) => {
    event.preventDefault();
    try {
      const trophyResponse = await fetch(
        `${process.env.REACT_APP_API_HOST}/trophies/default`
      );
      const trophyData = await trophyResponse.json();
      const trophyId = trophyData?trophyData.trophy._id:null;
      
      const myUser = displayUsers.find((element) => { return element.id === userId});
      const givenName = `${auth.username} vs ${myUser.username}`;
      const gameResponse = await fetch(
        `${process.env.REACT_APP_API_HOST}/games/generatename/${givenName}`);
      const gameData = await gameResponse.json();
      const gameName = gameData.name;

      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_HOST}/games/add`,
        'POST',
        JSON.stringify({
          name: `${gameName}`,
          creator: `${auth.userId}`,
          opponent: `${myUser.id}`,
          house: false,
          trophy: `${trophyId}`
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      const gameId = responseData.GameId;

      navigate(`/viewgame/${gameId}`);  
    } catch (err) {}
  };

  
  return (
      <React.Fragment>  
      {!isLoading && displayUsers && (

        <React.Fragment>
          <Grid container direction="column" style={{ minWidth: 300}}>
            <Grid item>
              <Typography color="black" style={{float: 'left', paddingTop: '5px' }} >
                <b>CREATE GAME</b>
              </Typography>

              <Link to="/"><Button variant="text" style={{ float: 'right'}}>Cancel</Button></Link>
              
            </Grid>
         
            <Grid item>
            <Typography variant="h5" color="secondary" style={{}}><br/> Play a friend</Typography>
            </Grid>
          </Grid>
            <List  sx={{ width: '100%', maxWidth: 400, bgcolor: 'white'}}>
              {displayUsers.map(user => (
                  <ListItem alignItems="flex-start" 
                    key={user.id} 
                    onClick={(value) => { eventSubmitHandler(value, user.id); }}>

                  <ListItemAvatar>
                    <Avatar  src={user.image} />
                  </ListItemAvatar>
                  <ListItemText><h6>{user.username}</h6></ListItemText>
                </ListItem>
                ))}
            </List>
            
            {showMore && <Button sx={{width:100, float:"left"}} onClick={loadMore}> see more </Button>}
            {showLess && <Button sx={{width:100, float:"left"}}onClick={loadLess}> see less </Button>}

            <Typography variant="h5" color="secondary" ><br/> <br/>Play someone new? Make them a friend!</Typography>
            
            <Link to="/peoplepage"><Button variant="text" style={{ float: 'left'}}>SEE FULL PLAYER LIST</Button></Link>
        </React.Fragment>  
      )}
      {error && (
        <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
      )}
    </React.Fragment>
  );
};

export default Events;
