import React from 'react';
//import { Link } from 'react-router-dom';

//import Avatar from '../../shared/components/UIElements/Avatar';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import './TopicItem.css';

const TopicItem = props => {
  return (
    <li className="topic-item">
      <Card className="topic-item__content">
        
          <div className="topic-item__info">
            <h2>{props.area}</h2>
            <Button to={`/admin/topics/${props.id}`}>{props.title}</Button>
          </div>
        
      </Card>
    </li>
  );
};

export default TopicItem;
