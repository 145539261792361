import React, {useContext} from 'react';

import { AuthContext } from '../../shared/context/auth-context';

const About = () => {
  const auth = useContext(AuthContext);

  return (

        <React.Fragment>
          <h3>
            This page is still under construction!
            <br/>
            Click on the logo upper left to return to somewhere useful
          </h3>
          { 
            auth.role==="ADMIN" && (<h4>{process.env.REACT_APP_ABOUT}</h4>)
          }
        </React.Fragment>

  );
};

export default About;
