import React , { useContext }from 'react';


import Grid from '@mui/material/Grid';

import { BetContext } from '../../shared/context/bet-context';

// removed for the time being
const AddWagerTopic = props => {
  const bet = useContext(BetContext);
  const topicImage = `/images/${bet.topicArea.charAt(0) + bet.topicArea.substring(1).toLowerCase()}Topic.png`;

  return (
       <React.Fragment>
          <Grid container> 
            <Grid item xs={2}>
              <img height="40" width="40" alt={bet.topicName} src={topicImage}/>
            </Grid>
            <Grid item xs={9.5} sx={{verticalAlign:"center"}}>
              <h5>{bet.topicName}</h5>
            </Grid>
        </Grid>

      </React.Fragment>
  );

  
  
};

export default AddWagerTopic;
