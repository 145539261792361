import React, {useState} from 'react';

import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
//import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
  VALIDATOR_MINLENGTH
} from '../../shared/helpers/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './Register.css';

const ChangePassword = ({userId, token, refresh}) => {
  const { error, newError, sendRequest } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);

  
  const [formState, inputHandler] = useForm(
    {
      currentpassword: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      },
      verifypassword:{
        value: '',
        isValid: false
      }
    }
  );

  const authSubmitHandler = async event => {
    event.preventDefault();

    if(formState.inputs.password.value.trim() !== formState.inputs.verifypassword.value.trim()){
      newError("Passwords do not match");
    } else {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/userrequests/changepassword`,
          'POST',
          JSON.stringify({
            id: userId,
            currentpassword: formState.inputs.currentpassword.value.trim(),
            password: formState.inputs.password.value.trim(),
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        );
        setShowSuccess(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <React.Fragment>
      <Card className="registration">
        <h5>Change your Casual Wager Password</h5>
        <hr />
        <form onSubmit={authSubmitHandler}>
        <Input
            element="input"
            id="currentpassword"
            type="password"
            label="Current Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter your current password"
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="verifypassword"
            type="password"
            label="Re-enter New Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a matching password."
            onInput={inputHandler}
          />
          {/* <Link to="/settings"><Button variant="contained">Cancel</Button></Link> */}
          <Button variant="contained" onClick={()=> refresh()}>Cancel</Button>
          
          <Button variant="contained" type="submit" disabled={!formState.isValid}>
            Change Password
          </Button>
        </form>
        {error && (
          <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
        )}
        {showSuccess && (
          <Alert severity="info"><AlertTitle>Successful Password Change</AlertTitle>
            Your password was reset. Please login with the new password<br/>
            <Link to="/"><Button variant="contained">Return to Home </Button></Link>
          </Alert>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ChangePassword;
