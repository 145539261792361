import { useState, useCallback, useEffect } from 'react';

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState();
  const [userImage, setUserImage] = useState(false);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState(false);
  const [dbStatus, setDbStatus] = useState("");
  const [timezone, setTimezone] = useState(0);
  const [playedSince, setPlayedSince] = useState(0);
  const [hasSeenAnnouncement, setHasSeenAnnouncement] = useState(true);

  const changeDBStatus = useCallback((dbStatus) => {
    setDbStatus(dbStatus);
  }, []);

  const login = useCallback((player, token) => {
    setToken(token);
    setUserId(player._id);
    setUserName(player.username);
    setUserRole(player.role);
    setUserImage(player.image);
    setHasSeenAnnouncement(player.hasseenannouncement);
    setTimezone(player.timezone)
    setPlayedSince(player.createddate)

    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: player._id,
        token: token,
        name: player.username,
        role: player.role,
        image: player.image,
        hasSeenAnnouncement:player.hasseenannouncement,
        timezone: player.timezone,
        playedSince: player.createddate,
      })
    );
    // eslint-disable-next-line
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setUserName(null);
    setUserRole(null);
    setUserImage(null);
    setHasSeenAnnouncement(false);
    setTimezone("")
    setPlayedSince(0)
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token
    ) {
      const player = {
        
          "_id": storedData.userId,
          "username": storedData.name,
          "role": storedData.role,
          "image": storedData.image,
          "hasSeenAnnouncement": storedData.hasSeenAnnouncement,
          "timezone": storedData.timezone,
          "playedSince": storedData.playedSince,
        
      }

      login(player, storedData.token);
    }
  }, [login]);

  return { token, login, logout, userId, userRole, userName, userImage, 
    hasSeenAnnouncement, playedSince, 
    setUserImage, dbStatus, changeDBStatus, timezone, setTimezone };
};