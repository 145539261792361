import React, {useState} from 'react';

import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
  VALIDATOR_MINLENGTH
} from '../../shared/helpers/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './Register.css';

const ResetPassword = () => {
  const { isLoading, error, newError, sendRequest } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const requestId = useParams().id;
  
  const [formState, inputHandler] = useForm(
    {
      password: {
        value: '',
        isValid: false
      },
      verifypassword:{
        value: '',
        isValid: false
      }
    }
  );

  const authSubmitHandler = async event => {
    event.preventDefault();

    if(formState.inputs.password.value.trim() !== formState.inputs.verifypassword.value.trim()){
      newError("Passwords do not match");
    } else {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/userrequests/resetpassword`,
          'POST',
          JSON.stringify({
            requestid: requestId,
            password: formState.inputs.password.value.trim()
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        setShowSuccess(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <React.Fragment>
      <Card className="registration">
        {isLoading && <LoadingSpinner asOverlay />}
        <h4>Reset your Casual Wager Password</h4>
        <hr />
        <form onSubmit={authSubmitHandler}>
        <Input
            element="input"
            id="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="verifypassword"
            type="password"
            label="Re-enter Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a matching password."
            onInput={inputHandler}
          />
          <Link to="/"><Button variant="contained">Cancel</Button></Link>
          <Button variant="contained" type="submit" disabled={!formState.isValid}>
            Reset Password
          </Button>
        </form>
        {error && (
          <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
        )}
        {showSuccess && (
          <Alert severity="info"><AlertTitle>Successful Password Reset</AlertTitle>
            Your password was reset. Please login with the new password<br/>
            <Link to="/"><Button variant="contained">Return to Home </Button></Link>
          </Alert>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
