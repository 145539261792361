import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Fab from '@mui/material/Fab'; 
import AddIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import BetsList from './BetsList';
import GameComments from './GameComments';
import GameLeaderboard from './GameLeaderboard';
import GameLeaderboardHouse from './GameLeaderboardHouse';
import { wagerCanceled, wagerDeclined } from '../../wagerStates';


const GameDetail = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [loadedGame, setLoadedGame] = useState();
  const [loadedBets, setLoadedBets] = useState();
  const [disableInput, setDisableInput] = useState(true);
  const [buttonText, setButtonText] = useState("Edit");
  const [previousName, setPreviousName] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [deletable, setDeletable] = useState(false);
  const gameId = useParams().gameId;
  const [nameSize, setNameSize]  = useState(40);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const gameData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/games/${gameId}?bywho=${auth.userId}`
        );
        const betData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/game/${gameId}`
        );
        adjustNameSize(gameData.game.name);
        setCurrentName(gameData.game.name);
        setPreviousName(gameData.game.name);
        //setOpponent(wagerOpponent(auth, gameData.game.creator.username, gameData.game.opponent.username));
        
        setLoadedGame(gameData.game);
        const myBets = betData.bets.filter(bet => bet.status !== wagerCanceled && bet.status !== wagerDeclined);

        setDeletable(myBets.length===0 && !gameData.game.house);
        setLoadedBets(myBets);
      } catch (err) {}
    };
    fetchEvent();
  },[gameId, sendRequest, auth],);

  const goBack = () => {
    navigate(-1);
  }

  const deleteGame = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/games/remove/${loadedGame._id}`,
        'POST',"",
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      goBack();
    } catch (err) {}
  }

  const adjustNameSize = (name) => {
    setNameSize(name.length>35?20:25);
  }

  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
    adjustNameSize(event.target.value);
  }

  const updateName = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/games/update`,
        'POST',
        JSON.stringify({
          name: `${currentName}`,
          id: `${loadedGame.id}`
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
    } catch (err) {}
  }
  useEffect(() => {
    setButtonText( disableInput?"Edit":"Save")
    if(disableInput && currentName!==previousName){
        updateName();
        setPreviousName(currentName);
    }
    // eslint-disable-next-line
  },[disableInput]);

  const smallGray = { fontSize: 12 , color: "gray" };

    return (
      <React.Fragment>
      {isLoading && (
       <div className="center">
         <LoadingSpinner />
       </div>
      )}

     {!isLoading && loadedGame && loadedBets &&(
      <React.Fragment>  
        
        <Grid container style={{ minWidth: 300, maxWidth:550}}>

          <Grid item xs={10}>
            <Typography  color = 'black'>             
                <TextField 
                  sx={{backgroundColor: 'grey.100'}}
                  inputProps={{ style: { fontSize: nameSize } }}
                  onChange={handleNameChange}
                  value={currentName}
                  variant="outlined"
                  fullWidth="true"
                  disabled={disableInput}
                /> 
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {!loadedGame.house && (
              <Button  variant="contained" onClick={()=>setDisableInput(!disableInput)}>
              {buttonText}
            </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography  variant="h6">
              {loadedGame.house?"House":"Head-to-Head"}
              <Link to={`/gamerules/${loadedGame.name}`} > Rules </Link>
            </Typography>

            <Typography style={smallGray}>
              {loadedGame.house?"":"for 5 BP"}
            </Typography>
          </Grid>
           
          <Grid item xs={12}>
            {!isLoading && deletable &&(
              <Button  title="Delete this game" onClick={deleteGame}>
                <DeleteForeverIcon sx={{ fontSize: 60 }}  color="secondary" />
              </Button>
            )}

            {!isLoading && loadedGame.house &&(
              <GameLeaderboardHouse players={loadedGame.housePlayers}/>
            )}
            {!isLoading && !loadedGame.house &&(
              <GameLeaderboard wagers={loadedBets}/>
            )}
            
            <GameComments comments={loadedGame.comments}/>
            <BetsList bets={loadedBets} game={loadedGame}/>
          </Grid> 

        </Grid>
          
        <Fab color="secondary" size="medium" aria-label="close" onClick={goBack}
            style={{position: 'fixed', right: '75px', top: '150px'}}>
              <AddIcon />
          </Fab>


      </React.Fragment>
     )}

     </React.Fragment>
    );    
}

export default GameDetail