import React , { useEffect, useState, useContext }from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { BetContext } from '../../shared/context/bet-context';
import { gameLeaderboard, houseGameLeaderboard } from '../../shared/helpers/game-formatters'
import { resolveGameAction } from '../../shared/helpers/game-formatters'
import { setOpponentInformation, setWagerDefaults } from '../../shared/helpers/utilities';

import "./GameStyles.css"
import { wagerInplay } from '../../wagerStates';

const GameHeader = props => {
  const {  sendRequest } = useHttpClient();
  const [commentColor, setCommentColor] = useState("black");
  const [rank, setRank] = useState(0);
  const [gameAction, setGameAction] = useState("");
  const gameType = props.game.house?"House":"H2H";
  const winType = props.game.house?"PICK5":"INFINITE"
  const [canWager, setCanWager] = useState(true);
  const [cannotWagerText, setCannotWagerText] = useState("");
  const auth = useContext(AuthContext);
  const bet = useContext(BetContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGame = async () => {
      await generateStatus();
    };
    fetchGame();
    // eslint-disable-next-line
  }, []);

  async function generateStatus(){

    const wagerList = await sendRequest(
      `${process.env.REACT_APP_API_HOST}/bets/game/${props.game.id}`
    );
   
    const latestView = props.game.creator.username===auth.username?props.game.creatorLastViewed:props.game.opponentLastViewed;
    const localWager = wagerList.bets[0];

    // determine if game or wager comments are new
    props.game.comments.every(comment => {
      if(comment.createddate > latestView){
        setCommentColor("secondary");
        return false;
      }
      return true;
    })

    
    wagerList.bets.forEach(element => {
      element.comments.forEach(comment => {
        if(commentColor!=="secondary" && comment.createddate > latestView){
          setCommentColor("secondary");
        }
      })
    });

    // get ranking of this player
    if(props.game.house){
      const rankMap = houseGameLeaderboard(props.game.housePlayers);
      if(rankMap.has(auth.username)){
        setRank(rankMap.get(auth.username).rank);
      }
      //also check if they can make a new wager

      if(props.game.name.includes("Formula 1")){
        setCanWager(!wagerList.bets.some(bet => (bet.creator.id.toString()=== auth.userId && bet.status===wagerInplay)));
        setCannotWagerText("Already Wagered");
      } else {
        setCanWager(false);
      }
      
    } else {
      const rankMap = gameLeaderboard(wagerList.bets);
      if(rankMap.size > 0){
        setRank( rankMap.keys().next().value===auth.username?1:2);
      }
    }
    
    setGameAction(resolveGameAction(auth, localWager));
  
  }

  const startNewWager = async () => {
    bet.gameName = props.game.name;
    bet.gameId = props.game.id
    bet.gameHouse = props.game.house;
    
    const yesPlayer = {
      id: auth.userId,
      username: auth.username,
      image: auth.userImage
    }
    bet.yesPlayer = yesPlayer;
    bet.creator = auth.userId;
    bet.betText = "";
    bet.topicId=null;
    const opponent = props.game.creator.username===auth.username?props.game.opponent:props.game.creator;
    setOpponentInformation(bet, opponent);
    await setWagerDefaults(auth, bet);  
    if(props.game.house){
      bet.topicId = props.game.houseTopic._id;
      bet.topicName = props.game.houseTopic.title;
      bet.topicArea = props.game.houseTopic.area;
      navigate(`/addbetevent/${bet.topicId}`);
    } else {
      navigate(`/addbettopic`);
    }
  }

//max's new colors
// const smallGray = { fontSize: 12 , color: "gray" };
// const small = { fontSize: 15};
// const big = { fontSize: 25};

  return (
       <React.Fragment>
                            
                <Grid container maxWidth="490px"  backgroundColor="white"
                    sx={{ border: 1, borderColor: 'black', width:'92%', mx: 2}}>
  
                    <Grid item xs={12}>
                      <div style={{float: 'left' , fontWeight: 'bold', paddingLeft: '5px'}}>   
                        <Typography variant="h5" color="black"> {props.game.name}</Typography>
                      </div> 
                      <div style={{float: 'right' , fontWeight: 'bold', paddingLeft: '5px', paddingRight: '10px'}}>   
                        <Typography variant="h6" color="black"> R: {rank} </Typography> 
                      </div>
                    </Grid>
                   
                    <Grid item xs={9}>
                      <div style={{textAlign: 'left' , paddingLeft: '5px'}}>   
                        <Typography variant="h6" color="gray">{gameAction}</Typography>  
                      </div>
                    </Grid>
                   
                    <Grid item xs={3}>
                      {canWager && (
                        <Button variant="contained" color='secondary' onClick={startNewWager}>
                          New Wager
                        </Button>
                      )}     
                       {!canWager && (
                        <Typography sx={{ font: 18}} color="secondary">{cannotWagerText}</Typography> 
                       )}               
                    </Grid>
                    

                  <Grid item xs={12} sx={{ border: 0}} backgroundColor="black" >                
                    <div style={{float: 'left' , fontWeight: 'bold',paddingLeft: '5px'}}>   
                      <Typography variant="h6" color={commentColor}> C </Typography>  
                    </div>
                    
                    <Typography variant="h6" color="white">
                      <div style={{ textAlign: 'right', fontWeight: 'bold', paddingLeft:'5px', paddingRight:'15px'}}>
                        {gameType}{" "}
                        <span style={{ textAlign: 'right', fontWeight:'normal', paddingLeft:'5px', paddingRight:'5px'}}>
                          {winType}
                        </span>
                      </div>
                    </Typography>
                  </Grid>

                </Grid>           
           
            <br/>
      </React.Fragment>
  );

  
  
};

export default GameHeader;
