import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../../shared/helpers/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Register.css';

const Register = () => {
  const auth = useContext(AuthContext);
  const {isLoading, error, sendRequest} = useHttpClient();
  const navigate = useNavigate();

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const authSubmitHandler = async event => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/signup`,
        'POST',
        JSON.stringify({
          username: formState.inputs.name.value.trim(),
          email: formState.inputs.email.value.trim(),
          password: formState.inputs.password.value.trim(),
          host: `${process.env.REACT_APP_HOST}`
        }),
        {
          'Content-Type': 'application/json'
        }
      );

      auth.login(responseData.player, 
        responseData.token);
      navigate('/profile');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <Card className="registration">
        {isLoading && <LoadingSpinner asOverlay />}
        <h4>Register to Casual Wager</h4>
        <hr />
        <form onSubmit={authSubmitHandler}>
          <Input
            element="input"
            id="name"
            type="text"
            label="Your Username"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a username."
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="email"
            type="email"
            label="E-Mail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
          />
          <Link to="/"><Button variant="contained">Cancel</Button></Link>
          <Button variant="contained" type="submit" disabled={!formState.isValid}>
            Sign Up
          </Button>
        </form>
        {error && (
          <Alert severity="error"><AlertTitle>Registration Error</AlertTitle>{error}</Alert>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Register;
