import React from 'react';

const UnderConstruction = () => {
  
  return (

        <React.Fragment>
          <h3>
            This page is still under construction!
            <br/>
            Click on the logo upper left to return to somewhere useful
          </h3>
        </React.Fragment>

  );
};

export default UnderConstruction;
