import React, {useState, useEffect, useContext} from 'react';
import { Link }  from 'react-router-dom'

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { AuthContext } from '../../shared/context/auth-context';

const PendingFriendList = props => {
  const [friends, setFriends] = useState('');
  const auth = useContext(AuthContext);

  useEffect(() => {
    const setup =  () => {
      setFriends(props.pending);
    };
    setup();
  }, [props.pending, setFriends]);

  // const rejectFriend = async (id) => {
  //   try {
  //     const outstring = JSON.stringify({
  //       player: auth.userId,
  //       friend: id
  //     });
  //     await sendRequest(
  //       `${process.env.REACT_APP_API_HOST}/users/unfriend`,
  //           'POST',
  //           `${outstring}`,
  //           {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + auth.token
  //           }
  //     );
  //     const newFriends = friends.filter((friend) => {return friend.friend.id!==id && friend.status==="pending"})
  //     setFriends(newFriends);
  //   } catch (err) {}
  // }

  // const acceptFriend = async (id) => {
  //   try {
  //     const outstring = JSON.stringify({
  //       player: auth.userId,
  //       friend: id, 
  //       status: "accepted"
  //     });
  //     await sendRequest(
  //       `${process.env.REACT_APP_API_HOST}/users/friendrequest`,
  //           'POST',
  //           `${outstring}`,
  //           {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + auth.token
  //           }
  //     );
  //     const newFriends = friends.filter((friend) => {return friend.friend.id!==id && friend.status==="pending"})
  //     setFriends(newFriends);
  //   } catch (err) {}
  // }
  
  if (friends.length > 0) {  
    return (
      <Grid container style={{ minWidth: 300 }}>
        <Grid item xs={12}>
          <h5><br/><b>Friend Requests</b></h5>
        </Grid>
        {friends.map((friend) => {
          return (
            <React.Fragment>
              <Grid item xs={1.5}>
                <Avatar 
                  alt={friend.friend.username}
                  src={friend.friend.image}
                  sx={{ width: 40, height: 40 }}/>
              </Grid>
              <Grid item sx={{height: '60px'}} xs={10}>
                <Typography variant="h5" color="#FF1493" style={{ fontWeight: 900 }} >
                  <Link color="#FF1493"  to={`/profile/${friend.friend.username}`} ><b>{friend.friend.username}</b></Link>
                   {`${auth.userId===friend.initiator?" waiting for their response": " wants to be your friend"}`}
                </Typography>
            </Grid>
            
            </React.Fragment>
          )
          })
        }
      </Grid>
    );
  } else {  
    return ('')
  }
};

export default PendingFriendList;
