import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import ErrorModal from '../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';

const Event = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [removedEvent, setRemovedEvent] = useState();
  const eventId = useParams().eventId;
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const removeEvent = async () => {
      try {
        const eventData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/events/remove/${eventId}`,
          'POST',"{}",
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        setRemovedEvent(eventData.EventId);

      } catch (err) {}
    };
    removeEvent();
  }, [sendRequest, eventId, auth]);

  const goBack = () => {
    navigate(-1);
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
        
      {!isLoading && removedEvent && (
        <React.Fragment>
          <>Event has been removed</><br/>
          <Button variant="contained" onClick={goBack}>Return to Events</Button>
          {error && (
            <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
          )}
        </React.Fragment>
      )}
      
    </React.Fragment>
  );
};

export default Event;
