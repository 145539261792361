import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormLabel from '@mui/material/FormLabel';

import {Typography} from '@mui/material';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { useSSEEvent } from '../../shared/hooks/sse-hook';

import ProfileLeaderboard from '../components/ProfileLeaderboard';
import AllTimeStats from '../components/AllTimeStats';
import PlayerHistory from '../components/PlayerHistory';
// import FriendList from '../components/FriendList';
// import { Container } from 'react-materialize';

const Profile = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const {startSSE, stopSSE} = useSSEEvent();
  const [player, setPlayer] = useState();
  const [friendText, setFriendText] = useState("");
  const [friendVariant, setFriendVariant] = useState("contained");
  const [loadedPlayerBets, setPlayerBets] = useState();
  const [notificationOpen, setNotificationOpen] = useState("");

  const paramUserName = useParams().username;

  function createPlayer( username, userId, role, image, playedSince, friends) {
    return { username, userId, role, image, playedSince, friends}; 
  }
  
  startSSE();

  useEffect(() => {
    const fetchBets = async () => {
      setFriendText("") 
      let userid = "";
      if(paramUserName!==undefined){
        try {
          const userByName = await sendRequest(
            `${process.env.REACT_APP_API_HOST}/users/${paramUserName}`
          );
          userid = userByName.user.id;
          setPlayer( createPlayer(userByName.user.username, userByName.user.id, 
            userByName.user.role, userByName.user.image, userByName.user.createddate, userByName.user.friends) );
            
          const hasFriend = userByName.user.friends.filter((friend) => {return friend.friend._id===auth.userId})
          if(hasFriend.length>0){
            if(hasFriend[0].status==="pending"){
              setFriendText(hasFriend[0].initiator===auth.userId?" Friend Requested - Cancel":"Accept Friend")
            } else{
              setFriendText("UnFriend")
            }
            setFriendVariant("text")
          } else {
            setFriendText("Add Friend")
            setFriendVariant("contained")
          }
         } catch (err) {}
      } else {
        const authData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/users/${auth.username}`
        );
        setPlayer( createPlayer(auth.username, auth.userId, auth.role, auth.userImage, 
                                auth.playedSince, authData.user.friends) );
        userid = auth.userId;
      }
      try {
        const betData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/player/${userid}`
        );
        setPlayerBets(betData.bets);
      } catch (err) {}
    };
    fetchBets();
    //eslint-disable-next-line
  }, [paramUserName]);


  useEffect(() => {
    return () => {
      stopSSE("profile");
    }
    // eslint-disable-next-line
  }, [])

  // const resetDB = async () => {
  //   try {
  //     await sendRequest(
  //       `${process.env.REACT_APP_API_HOST}/userrequests/restoretestdb`,
  //           'POST',
  //           ``,
  //           {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + auth.token
  //           }
  //     );
      
  //   } catch (err) {}
  // }

  // const restoreDB = async () => {
  //   try {
  //     await sendRequest(
  //       `${process.env.REACT_APP_API_HOST}/userrequests/restoreproddb`,
  //           'POST',
  //           ``,
  //           {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + auth.token
  //           }
  //     );
  //     auth.changeDBStatus("");
  //   } catch (err) {}
  // }

  const friendRequest = async () => {
    try {
      const status = friendText==="Accept Friend"?"accepted":"pending";
      const outstring = JSON.stringify({
        player: auth.userId,
        friend: player.userId,
        status: status
      });
      const apiName = friendText==="Add Friend"||friendText==="Accept Friend"?"friendrequest":"unfriend";
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/${apiName}`,
            'POST',
            `${outstring}`,
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
      );
      const newFriendText = apiName==="friendrequest"?"UnFriend":"Add Friend";
      setFriendText(newFriendText);
      setNotificationOpen(true);
    } catch (err) {}
  }


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedPlayerBets && (
        <React.Fragment>

        <Grid container >
          <Grid item xs={12}> 

          <Paper>
            <Typography color="black" fontSize={20} sx={{marginTop:"20px"}}>
                  <b>PROFILE</b>
            </Typography>

            <Grid container>
        
              <Grid item xs={9} >
                <Typography color="Black" fontSize={36}>
                  {player.username}
                </Typography>
              </Grid>
              <Grid item xs={3} align="center">
                <Avatar src={player.image} alt={player.username} style={{ width: 120, height: 120 }}/>       
              </Grid>
              {auth.userId!==player.userId && (
                <React.Fragment>
                  <Grid item xs={9}> </Grid>
                  <Grid item xs={3} align="center" >
                    <Button color = "primary" onClick={friendRequest} variant={friendVariant}>{friendText}</Button>       
                  </Grid>
                </React.Fragment>
              )}
              
              <Grid item xs={12}>
                <Typography color="grey" fontSize={12}>
                  since {dayjs(player.playedSince).format('MMM DD YYYY ')}
                  <Typography color="black" fontSize={12} display={'inline'}></Typography> 
                  <Typography color="secondary" fontSize={12} display={'inline'}></Typography>
                </Typography>
              </Grid>

            </Grid>
            
            <Snackbar
              open={notificationOpen}
              onClose={() => { setNotificationOpen(false);}}
              autoHideDuration={6000}
              message={"Your friend request has been sent!"}
            />
            
            <AllTimeStats wagers={loadedPlayerBets} player={player}/>
          </Paper>

          </Grid>
  
          <Grid item xs={12}>
            <Typography color="black" fontSize={20} sx={{marginTop:"20px"}}>
                <b>FRIENDS</b>
            </Typography>
            <ProfileLeaderboard wagers={loadedPlayerBets} player={player}/>
          </Grid>

          <PlayerHistory wagers={loadedPlayerBets} player={player}/>
        </Grid>
           
        </React.Fragment>
        
      )}
    </React.Fragment>
  );
};

export default Profile;
