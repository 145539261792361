import React, { useEffect, useState, useContext } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import { AuthContext } from '../shared/context/auth-context';

import Input from '../shared/components/FormElements/Input';
import {VALIDATOR_REQUIRE} from '../shared/helpers/validators';
import ErrorModal from '../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';
import { useForm } from '../shared/hooks/form-hook';
import AnnouncementsList from './components/AnnouncementsList';

const Announcements = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedAnnouncements, setLoadedAnnouncements] = useState();

  const [formState, inputHandler] = useForm(
    {
      message: {
        value: '',
        isValid: true
      }
    },
    true
  );

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/announcements`
        );

        setLoadedAnnouncements(responseData.announcements);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest], loadedAnnouncements);

  const announcementSubmitHandler = async event => {
    event.preventDefault();
  
    const message= formState.inputs.message.value;
   
    if( message){
      try {
        var outstring = JSON.stringify({
          message: message,
          creator: auth.userId
        });
         const addedAnnounce = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/announcements/add`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        
        let newannouncement = [addedAnnounce.announcement, ...loadedAnnouncements];
        setLoadedAnnouncements(newannouncement)
      } catch (err) {}
    }
  }

  const updateAnnouncement = async (position) => {
    setLoadedAnnouncements(loadedAnnouncements => 
                            loadedAnnouncements.filter(data=> data.id !== position));
  }


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
       {!isLoading && loadedAnnouncements && (
        <React.Fragment>
        <form  onSubmit={announcementSubmitHandler}>

          <Input
            id="message"
            element="input"
            type="text"
            label="message"
            errorText="Please enter some message for the CW masses."
            onInput={inputHandler}
            initialValue=""
            validators={[VALIDATOR_REQUIRE()]}
          />
        
          <Button variant="contained" type="submit">Add Announcement</Button>
        </form>
        <AnnouncementsList items={loadedAnnouncements} removeUpdate={(position) => updateAnnouncement(position)}/>
        </React.Fragment>
      )}

      {error && (
          <Alert severity="error"><AlertTitle>Input Error</AlertTitle>{error}</Alert>
      )}
    </React.Fragment>
  );
};

export default Announcements;
