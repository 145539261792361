import React, {useContext, useState} from 'react';

import Button from '@mui/material/Button';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './EventItem.css';

const AnnouncementItem = props => {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [username, setUsername] = useState(props.creator.username);

  const removeAnnouncement = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/announcements/remove/${props.id}`,
          'POST',"{}",
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
      setUsername("");
      props.removeUpdate(props.id);
    } catch(err){}
  }

  return (
    <React.Fragment>

      <li className="event-item">
            <div className="event-item__info">
              <h2>
                {props.message} <br/>
                {username} created date:{props.createddate} <br/>
              </h2>
              <Button variant="contained" onClick={removeAnnouncement}>Remove Announcement</Button>
            </div>
      </li>
      
    </React.Fragment>
  );
};

export default AnnouncementItem;
