import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../../../shared/context/auth-context';
import './NavLinks.css';



const NavLinks = props => {
  const auth = useContext(AuthContext);
  const [testDB, setTestDB] = useState(auth.dbStatus);
  
  
  useEffect(() => {
    setTestDB(auth.dbStatus);
  }, [auth.dbStatus]);

  return (
    <ul className="nav-links">
       
      {auth.isLoggedIn && (
        <React.Fragment>
           
          <li>
          <Typography variant="h4" color="red">{testDB}</Typography>
          </li>
           <li>
          <NavLink to={`/profile`}>
            <Avatar 
              alt={auth.username}
              src={auth.userImage}
              sx={{ width: 40, height: 40 }}
            />
          </NavLink>
         
        </li>
        </React.Fragment>
      )}
     
    </ul>
  );
};

export default NavLinks;
