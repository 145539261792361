import React from 'react';

import Card from '../shared/components/UIElements/Card';
import Button from '../shared/components/FormElements/Button';
import ErrorModal from '../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';
import './Admin.css';

const Admin = () => {
  const { isLoading, error, clearError } = useHttpClient();
  
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
       <React.Fragment>
         <div className="admin-list">
          <div className="admin-item">
            <Card>
              <Button to="/admin/users">Users</Button>
            </Card>
          </div>
          <div className="admin-item">
            <Card>
              <Button to="/admin/topics">Topics</Button>
            </Card>
          </div>
          <div className="admin-item">       
           <Card>
             <Button to="/admin/events">Events</Button>
           </Card>
          </div>
          <div className="admin-item">       
           <Card>
             <Button to="/admin/arbitration">Arbitrate Events</Button>
           </Card>
          </div>
          <div className="admin-item">       
           <Card>
             <Button to="/admin/snacksarbitration">Snacks Arbitration</Button>
           </Card>
          </div>
          <div className="admin-item">       
           <Card>
             <Button to="/admin/snacksvalidation">Snacks Validation</Button>
           </Card>
          </div>
          <div className="admin-item">       
           <Card>
             <Button to="/admin/trophies">Trophies</Button>
           </Card>
          </div>
          <div className="admin-item">       
           <Card>
             <Button to="/admin/announcements">Announcements</Button>
           </Card>
          </div>
          </div>        
       </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Admin;
