import React , {useEffect, useState} from 'react';
import { Link }  from 'react-router-dom'

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Grid } from '@mui/material';

import Card from '../../shared/components/UIElements/Card';
import {houseGameLeaderboard} from '../../shared/helpers/game-formatters'

const GameLeaderboardHouse = ({players}) => {

  const [playersMap, setPlayersMap] = useState(new Map());
  
  useEffect(() => {
    setPlayersMap(houseGameLeaderboard(players));
    // eslint-disable-next-line
  }, [players]);

  if (playersMap.size === 0) {
    return (
      <div className="center">
        <Card>
          <h4>No arbitrated wagers found.</h4>
        </Card>
      </div>
    );
  }
  
  return (
    <Grid container  style={{ minWidth: 300 }}>
       <Grid item xs={12}>
            <Paper>
              <Grid container xs={12} sx={{textAlign:"left"}}>
                
                <Grid item xs={4}> </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Rank</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Score</Typography>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Paper>
        </Grid>
        <Divider width = '100%'></Divider>
        
      {[...playersMap].map(([name, stats]) => {
        return (
          <React.Fragment>
            <Grid item xs={4}>
                <Typography color="secondary" fontSize={24}>
                  <Link  color="secondary" to={`/profile/${name}`} ><b>{name}</b></Link>
                </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color="grey" fontSize={24}>{stats.rank}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color="grey" fontSize={24}>{stats.score}</Typography>  
            </Grid>
            <Grid item xs={4}></Grid>
          </React.Fragment>
        )
        })
      }
    </Grid>
  );
};

export default GameLeaderboardHouse;
