import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import {
  VALIDATOR_EMAIL,
} from '../../shared/helpers/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './Register.css';

const RequestPassword = () => {
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);
 
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: '',
        isValid: false
      }
    }
  );

  const authSubmitHandler = async event => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/userrequests/resetpasswordrequest`,
        'POST',
        JSON.stringify({
          email: formState.inputs.email.value.trim(),
          host: `${process.env.REACT_APP_HOST}`
        }),
        {
          'Content-Type': 'application/json'
        }
      );
      setShowSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <Card className="registration">
        {isLoading && <LoadingSpinner asOverlay />}
        <h4>Reset your Casual Wager Password</h4>
        <hr />
        <form onSubmit={authSubmitHandler}>
          <Input
            element="input"
            id="email"
            type="email"
            label="E-Mail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter your email address."
            onInput={inputHandler}
          />
          <Link to="/"><Button variant="contained">Cancel</Button></Link>
          <Button variant="contained" type="submit" disabled={!formState.isValid}>
            Request New Password
          </Button>
        </form>
        {error && (
          <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
        )}
        {showSuccess && (
          <Alert severity="info"><AlertTitle>Password Request Sent</AlertTitle>
            Check your email for a reset message<br/>
            <Link to="/"><Button variant="contained">Return to Home </Button></Link>
          </Alert>
        )}
      </Card>
    </React.Fragment>
  );
};

export default RequestPassword;
