import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { BetContext } from '../../shared/context/bet-context';
import {  timeFormatterWithText} from '../../shared/helpers/formatters'
import './BasicForm.css';
import AddWagerTitle from '../components/AddWagerTitle';


import AddWagerBody from '../components/AddWagerBody';

// page that comes up when reviewing a wager 

const AddBetPreview = () => {
  const auth = useContext(AuthContext);
  const bet = useContext(BetContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const navigate = useNavigate();


  const eventSubmitHandler = async event => {
    event.preventDefault();
    try {
      
      //get default topic if not one already assigned
      if(bet.topicId===null){
        const defaultTopic = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/topics/default`);
        bet.topicId = defaultTopic.topic.id;
        bet.topicName = defaultTopic.topic.title;
       }

      // create open event if we need to
      if(bet.topicName.toLowerCase()==="open"){
       
        if (bet.eventEndDate < bet.wagerCloseDate){
          bet.wagerCloseDate = bet.eventEndDate;
        }
        if (bet.eventEndDate < bet.eventStartDate){
          bet.eventStartDate = bet.eventEndDate;
        }

        const adjustedWagerCloseDate = bet.wagerCloseDate.tz('Europe/London')
        const adjustedEventStartDate = bet.eventStartDate.tz('Europe/London')
        const adjustedEventCloseDate = bet.eventEndDate.tz('Europe/London')
        
        var eventString = JSON.stringify({
          name: bet.eventTitle,
          wagerCloseDate: adjustedWagerCloseDate.valueOf(),
          eventStartDate: adjustedEventStartDate.valueOf(),
          eventCloseDate: adjustedEventCloseDate.valueOf(),
          topic: `${bet.topicId}`
        });
        const eventData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/events/add`,
          'POST',
          `${eventString}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        bet.eventId = eventData.EventId;
      }

      var outstring = JSON.stringify({
        creator: `${bet.creator}`,
        opponent: `${bet.opponent}`,
        yesplayer: `${bet.yesPlayer.id}`,
        noplayer: `${bet.noPlayer.id}`,
        text: `${bet.betText}`,
        event: `${bet.eventId}`,
        game: `${bet.gameId}`
      });
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/bets/add`,
        'POST',
        `${outstring}`,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );

      navigate('/');
    } catch (err) {}
  }

  const goBack= () =>{
    navigate("/addbettext");
  }
  const smallGray = { fontSize: 12 , color: "gray" };
  const big = { fontSize: 25};

  return (
    <React.Fragment>
      {isLoading && (
        <div className="center">
         <LoadingSpinner />
        </div>
      )}

      {!isLoading  && (     
       <React.Fragment>
        <Grid container sx={{ border: 0, borderColor: 'grey.500', width:'92%', mx: 2}} >

          <Grid item xs={8}>
            <Typography color="black" style={{  paddingTop: '10px' ,paddingLeft: '5px' }} >
              <b>CREATE WAGER</b>
            </Typography>
            
          </Grid>
          <Grid item xs={4}>
            <Link to="/"><Button variant="text" sx={{ float: 'left' , paddingTop: '10px' }}>Cancel</Button></Link>
          </Grid>

          <AddWagerTitle/>
          
          <Typography Typography color = 'secondary' style={big} marginTop={5}>  
            <div style={{float: 'left' , paddingLeft: '10px'}}>Ready to send?</div> 
            <br></br> 
          </Typography>
            
          <Grid container maxWidth="sm" sx={{backgroundColor: 'grey.300', mx: 2}} >
          
            <Grid item xs={12}>
              <Typography style={smallGray}>
                  <div style={{float: 'left', marginTop:'10px', paddingLeft: '10px'}}> 
                    <b>{bet.eventTitle}</b><br/>
                    {timeFormatterWithText("done", bet.eventEndDate, "")} 
                  </div> 
              </Typography>

              <Typography color="white" >
                <div style={{ float: 'right', backgroundColor: '#ff00dc' , paddingLeft:'10px', paddingRight:'10px' , paddingTop:'10px', paddingBottom:'10px'}}><b>INVITE</b></div>
              </Typography>
            
              <br/>
              <br/>

              <AddWagerBody/>

            </Grid>
          </Grid>
          
          <Grid item xs={3} ></Grid>
          <Grid item xs={-4} marginTop={2}  marginBottom={2}> 
           <Button color = 'primary' variant="text" onClick={goBack}>Back</Button>  
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3} marginTop={2}  marginBottom={2}>
            <Button color='primary' variant="contained" onClick={eventSubmitHandler}>Confirm</Button>
          </Grid>
        
        </Grid>
       </React.Fragment>
      )}

      {error && (
        <Alert severity="error"><AlertTitle>Bet Creation Error</AlertTitle>{error}</Alert>
      )}
      </React.Fragment>
  )
  
}

export default AddBetPreview;
