import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Input from '../shared/components/FormElements/Input';
import {
  VALIDATOR_REQUIRE
} from '../shared/helpers/validators';
import { useForm } from '../shared/hooks/form-hook';
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import './Form.css';

const AddTrophy = () => {
  const auth = useContext(AuthContext);
  const { error, sendRequest } = useHttpClient();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);
  const [errorText, setErrorText] = useState();
  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
      if(fileReader.result.length > 150000){
        setIsValid(false);
        setErrorText("File too large. Please Pick a file less than 150k");
      } else {
        setErrorText("");
        setIsValid(true);
      }
    };
    fileReader.readAsDataURL(file);
  }, [file]); 


  const pickedHandler = event => {
    let pickedFile;
    if (event.target.files && event.target.files.length >0) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
    } else {
      setIsValid(false);
      setErrorText("Please Pick a file less than 100k");
    }
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: '',
        isValid: false
      },
      description: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const eventSubmitHandler = async event => {
      event.preventDefault();
    
      try {
        var outstring = JSON.stringify({
          name: formState.inputs.name.value,
          description: formState.inputs.description.value,
          image: previewUrl
        });
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/trophies/add`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        navigate(-1);
      } catch (err) {}
  
  };


  return (
    <React.Fragment>
    
      {(
        <form className="form" onSubmit={eventSubmitHandler}>
          <Input
            id="name"
            element="input"
            type="text"
            label="name"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a trophy name."
            onInput={inputHandler}
            initialValue=""
            initialValid={false}
          />
          <Input
            id="description"
            element="input"
            type="text"
            label="description"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a description of the trophy."
            onInput={inputHandler}
            initialValue=""
            initialValid={false}
          />
          <div >
            <input
              id="image"
              ref={filePickerRef}
              style={{ display: 'none' }}
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={pickedHandler}
            />
            <div className={`image-upload`}>
              <div className="image-upload__preview">
                {previewUrl && <img src={previewUrl} alt="Preview" />}
                {!previewUrl && <p>Please pick an image.</p>}
              </div>
              <Button variant="contained" onClick={pickImageHandler}>New Image</Button>
            </div>
            {!isValid && <p className="error-text">{errorText}</p>}
          </div>

          <Button variant="contained" type="submit" disabled={!formState.isValid}>
            Add Trophy
          </Button>
        </form>
        
      )}
      {error && (
          <Alert severity="error"><AlertTitle>Input Error</AlertTitle>{error}</Alert>
      )}
    </React.Fragment>
  );
};

export default AddTrophy;
