import React, { useContext, useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import { useHttpClient } from '../shared/hooks/http-hook';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../shared/context/auth-context';
import './ArbitrateItem.css';

const RewriteItem = props => {
  const betId = useParams().betId;
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedBet, setLoadedBet] = useState();
  const [resultsText, setResultsText] = useState();
  const auth=useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const betData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/${betId}`
        );
       
        setLoadedBet(betData.bet);
      } catch (err) {}
    };
    fetchEvent();
    // to prevent stupid warning on dependencies
    // eslint-disable-next-line
  },[betId]);

  

  async function rewriteBet(action){
    try {
        var outstring = JSON.stringify({
          betid: `${betId}`,
          arbitration: `${auth.id}`,
          results: `${resultsText}`
        });
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/rewritebet`,
          'POST',
          `${outstring}`,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
    } catch (err) {}
    goBack();
  }

  const changeHandler = event => {
    setResultsText(event.target.value) 
  };
  const goBack = () => {
    navigate(-1);
  }

  return (
    <React.Fragment>
    {isLoading && (
     <div className="center">
       <LoadingSpinner />
     </div>
    )}

   {!isLoading && loadedBet && (
     <React.Fragment>
        <Button onClick={goBack} variant="contained">Cancel</Button>
        <div className="bet-item__info">
          <h2>{loadedBet.event.topic.title}:{loadedBet.event.name}<br/>{loadedBet.text}<br/></h2>
          <h2>Created by {loadedBet.creator.username} against {loadedBet.opponent.username}<br/>arbitrated?:{loadedBet.arbitrated? "Yes":"No"}</h2>
          <br/><br/>
          Enter some Results text below and then press one of "Wins" buttons
          <input placeholder='Rewrite Reasons' onChange={changeHandler} />
          No Snacks Rewrite Available Yet
          <Button onClick={rewriteBet} variant="contained">Rewrite</Button>
        </div>
    </React.Fragment>
   )}
   </React.Fragment>
  );
};

export default RewriteItem;
