import React , { useContext, useState }from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import { red } from '@mui/material/colors'

import { BetContext } from '../../shared/context/bet-context';

const AddWagerBodyEditable = props => {
  const bet = useContext(BetContext);
  const [ ,setYesPlayer] = useState(bet.yesPlayer);
  const [ ,setNoPlayer] = useState(bet.noPlayer);

  const switchPlayers = () => {
    const tempPlayer = bet.yesPlayer
    bet.yesPlayer = bet.noPlayer;
    bet.noPlayer = tempPlayer;
    setYesPlayer(bet.noPlayer);
    setNoPlayer(tempPlayer);
  }

  const textChangeHandler = event => {
    
    const text = event.target.value;
    if(text){
      bet.betText = text;
      props.inputHandler(false);
    } else {
      props.inputHandler(true);
    }
  }

  return (

       <React.Fragment>
  
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            minWidth={350}
          >
            <form className="form" >
              <textarea
                id="bettext"
                rows={6}
                style={{height:150}}
                wrap={true}
                onChange={textChangeHandler}
              />             
            </form>

            <Grid  item xs={2.5}>
              <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              >
                <Typography color="black" style={{  align: "left", fontWeight: 'bold', paddingTop:'15px',paddingRight:'25px' }}>
                  <div align="center">
                    <Avatar 
                      alt={bet.yesPlayer.username}
                      src={bet.yesPlayer.image}
                      sx={{ bgcolor: red[500] }} aria-label="recipe" />
                
                    <h4>YES</h4>
                  </div>
                </Typography>
                <Typography color="primary">
     
                <Button variant="text" onClick={switchPlayers}>Switch</Button>
     
                </Typography>

                <Typography color="black" style={{  align: "right", fontWeight: 'bold', paddingTop:'15px', paddingLeft:'25px'}}>
                  <div align="center">
                    <Avatar 
                      alt={bet.noPlayer.username}
                      src={bet.noPlayer.image}
                      sx={{ bgcolor: red[500] }} aria-label="recipe"
                    />
                    <h4>NO</h4>
                  </div>
                </Typography>
                
              </Grid>
                   
            </Grid>   

          </Grid>

      </React.Fragment>

  );

  
  
};

export default AddWagerBodyEditable;
