import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import EventsList from './components/EventsList';
import ErrorModal from '../shared/components/UIElements/ErrorModal';
import Button from '../shared/components/FormElements/Button';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';

const Events = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedEvents, setLoadedEvents] = useState();
  const [loadedTopic, setLoadedTopic] = useState();
  const topicId = useParams().topicId;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const topicData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/topics/${topicId}`
        );
        setLoadedTopic(topicData.topic);

        const responseData = await sendRequest(
           `${process.env.REACT_APP_API_HOST}/events/topic/${topicId}`
         );
         setLoadedEvents(responseData.events);

      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, topicId]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
        
      {!isLoading && loadedEvents && (
        <React.Fragment>
          <h3>Topic {loadedTopic.title}</h3><br/>
          <Button to={`/admin/addevent/${topicId}`}>Add Event</Button>
          <EventsList items={loadedEvents} />
        </React.Fragment>
      )}
      
    </React.Fragment>
  );
};

export default Events;
