import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors'


//shows up on bet detail page

const BetPlayersH2H = ({yesPlayer, noPlayer}) => {
  
  
    return (
      <React.Fragment>
          <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <div className="yes-no-block">
              <Typography color="black" style={{fontWeight: 900 }}>
                <div align="center">
                <Avatar 
                  alt={yesPlayer.username}
                  src={yesPlayer.image}
                  sx={{ bgcolor: red[500] }} aria-label="recipe"
                />
                <h6 >{yesPlayer.username}</h6>
                </div>
              </Typography>

              <Typography variant="h4" color="black">
                 <div><b>YES<span> &nbsp; &nbsp; NO</span></b></div>
              </Typography>
              
              <Typography color="black" style={{  align: "center", fontWeight: 900 }}>
                <div align="center">
                <Avatar 
                  alt={noPlayer.username}
                  src={noPlayer.image}
                  sx={{ bgcolor: red[500] }} aria-label="recipe"
                />
                <h6 >{noPlayer.username}</h6>
                </div>
              </Typography>
            </div>
          </Grid>
        </Grid>
     </React.Fragment>
    );
  

  
};

export default BetPlayersH2H;
