import React , { useContext }from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors'

import { BetContext } from '../../shared/context/bet-context';

//page that comes up after clicking "review wager"

const AddWagerBody = props => {
  const bet = useContext(BetContext);
  const splitText = bet.betText.split('\n');

  return (
       <React.Fragment>
  
        <Typography variant="h4" color="black"   style={{marginTop: '10px' ,marginBottom: '40px', float: 'left' ,paddingLeft: '10px'}}>
          <div>
            {splitText.map((line, index) => (
              <React.Fragment key={index}>
              {line}
              <br />
              </React.Fragment>
            ))}
          </div>
        </Typography>

        {!bet.gameHouse&& (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={0.5}
          >
           
            <Grid item>
              <div className="yes-no-block">
                
                <Typography color="black" style={{fontWeight: 900 }}>
                  <div align="center">
                  <Avatar 
                    alt={bet.yesPlayer.username}
                    src={bet.yesPlayer.image}
                    sx={{ bgcolor: red[500] }} aria-label="recipe"
                  />
                  <h6 >{bet.yesPlayer.username}</h6>
                  </div>
                </Typography>

                <Typography variant="h4" color="black">
                   <div><b>YES<span> &nbsp; &nbsp; NO</span></b></div>
                </Typography>
                              
                <Typography color="black" style={{  align: "center", fontWeight: 900 }}>
                  <div align="center">
                  <Avatar 
                    alt={bet.noPlayer.username}
                    src={bet.noPlayer.image}
                    sx={{ bgcolor: red[500] }} aria-label="recipe"
                  />
                  <h6 >{bet.noPlayer.username}</h6>
                  </div>
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}
          
          

      </React.Fragment>
  );

  
  
};

export default AddWagerBody;
