import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';
import {AdminList} from './components/AdminList';

const WagerArbitrationList = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const [wagers, setWagers] = useState();
  const eventId = useParams().eventId;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/unarbitrated`
        );

        setWagers(responseData.bets);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, eventId]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && wagers && (
        <AdminList wagers={wagers}/>
      )}
    </React.Fragment>
  );
};

export default WagerArbitrationList;
