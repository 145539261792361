import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorModal from '../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';

const Event = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedEvent, setLoadedEvent] = useState();
  const eventId = useParams().eventId;

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/events/${eventId}`
        );
        setLoadedEvent(eventData.event);

      } catch (err) {}
    };
    fetchEvent();
  }, [sendRequest, eventId]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
        
      {!isLoading && loadedEvent && (
        <React.Fragment>
          <>Loaded for Event {loadedEvent.name}</><br/>
          load props
        </React.Fragment>
      )}
      
    </React.Fragment>
  );
};

export default Event;
