import React, { useEffect, useState, useContext} from 'react';
import {  useNavigate, useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { BetContext } from '../../shared/context/bet-context';
import { AuthContext } from '../../shared/context/auth-context';
import AddWagerTitle from '../components/AddWagerTitle';
import { getTimezoneParameter } from '../../shared/helpers/utilities';
import AddWagerTopic from '../components/AddWagerTopic';

//not sure how this will be implemented but rn im not using it

const Events = () => {
  const bet = useContext(BetContext);
  //const dateFormat = 'MM/DD/YYYY HH:mm';
  const timezoneFormat = 'MM/DD/YYYY HH:mm z';
  const auth = useContext(AuthContext);
  
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedEvents, setLoadedEvents] = useState();
  const navigate = useNavigate();
  const topicId = useParams().topicId;
  const backNavigate = bet.gameHouse ? `/` : `/addbettopic`

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/events/topicactive/${topicId}`
        );
        setLoadedEvents(responseData.events);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, topicId]);
 

  const eventSubmitHandler = (event, id) => {
    event.preventDefault();
    try {
      const myEvent = loadedEvents.find((element) => { return element.id === id});

      if(myEvent){
        bet.eventId = myEvent.id;
        bet.eventTitle = myEvent.name;
        bet.eventDetails = myEvent.details;

        bet.wagerCloseDate = dayjs(myEvent.wagerCloseDate).utc().tz(getTimezoneParameter(auth.timezone));
        bet.eventStartDate = dayjs(myEvent.eventStartDate).utc().tz(getTimezoneParameter(auth.timezone));
        bet.eventEndDate = dayjs(myEvent.eventCloseDate).utc().tz(getTimezoneParameter(auth.timezone));
        navigate(`/addbettext`);  
      }
    } catch (err) {
      console.log("Error loading Events");
    }
  };


  return (
    <React.Fragment>

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && loadedEvents && loadedEvents.length === 0 && (
        <div className="center">
          <h4>No Events Found!</h4>
        </div>
      )}

      {!isLoading && loadedEvents && loadedEvents.length > 0 && (
          <React.Fragment>

            <AddWagerTitle />
            {!bet.gameHouse && (<div align="right"><Link to="/addbetuser"><Button>change</Button></Link></div>)}
            <Typography color="#FF1493" style={{ fontWeight: 900 }} variant="h6">Topic</Typography>
            <AddWagerTopic />
            {!bet.gameHouse && (<div align="right"><Link to="/addbettopic"><Button>change</Button></Link></div>)}
            
            <Typography color="#FF1493" style={{ fontWeight: 900 }} variant="h6"><i>Events</i></Typography>
            <List  sx={{ width: '100%', maxWidth: 500, bgcolor: 'white'}}>
              {loadedEvents.map(event => (
                  <ListItem alignItems="flex-start" key={event.id} 
                    onClick={(value) => eventSubmitHandler(value,event.id)}>
                    <ListItemText
                      primary={event.name}
                      secondary={dayjs(event.eventStartDate).utc().tz(getTimezoneParameter(auth.timezone)).format(timezoneFormat)} 
                    />
                  </ListItem>           
                ))}
            </List>
            <div width="100" align="center"><Link to="/"><Button >cancel</Button></Link></div>

          </React.Fragment>

      )}
      
      {!isLoading && loadedEvents && loadedEvents.length === 0 && (
        <div className="center">
          <Link to={backNavigate}><Button variant="contained">Go Back</Button></Link>
          <br/><br/>
        </div>
      )}
      {error && (
        <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
      )}

    </React.Fragment>
  );
};

export default Events;
