import React, { useEffect, useContext, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import {Button, Typography} from '@mui/material';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { useSSEEvent } from '../../shared/hooks/sse-hook';


import CasualWagerAvatar from './CasualWagerAvatar';
import ChangePassword from './ChangePassword';

const Settings = () => {
  const [showPassword, setShowPassword] = useState(false);
  const auth = useContext(AuthContext);
  const {   sendRequest } = useHttpClient();
  const {startSSE, stopSSE} = useSSEEvent();
  const myoffset=auth.timezone===undefined?"EST":auth.timezone;
  
  startSSE();

  useEffect(() => {
    return () => {
      stopSSE("settings");
    }
    // eslint-disable-next-line
  }, [])

  const passwordChangeHandler = () => {
    setShowPassword(false)
  }  

  // const resetDB = async () => {
  //   try {
  //     await sendRequest(
  //       `${process.env.REACT_APP_API_HOST}/userrequests/restoretestdb`,
  //           'POST',
  //           ``,
  //           {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + auth.token
  //           }
  //     );
      
  //   } catch (err) {}
  // }

  // const restoreDB = async () => {
  //   try {
  //     await sendRequest(
  //       `${process.env.REACT_APP_API_HOST}/userrequests/restoreproddb`,
  //           'POST',
  //           ``,
  //           {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + auth.token
  //           }
  //     );
  //     auth.changeDBStatus("");
  //   } catch (err) {}
  // }

  const changeTimezone = (e, value) => {
    try {
      var outstring = JSON.stringify({
        id: `${auth.userId}`,
        timezone: `${value}`
      });
      sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/newtimezone`,
        'POST', 
        outstring,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      auth.setTimezone(value);
    } catch (err) {    }

  }
 

  return (
    <React.Fragment>
      
        <Grid container >
          <Grid item xs={12}> 
            <Paper>
              <Typography color="black" fontSize={20} sx={{marginTop:"20px"}}>
                    <b>Settings</b>
              </Typography>

              <Grid item xs={12} >
                <Typography color="Black" fontSize={36}>
                  {auth.username}
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <CasualWagerAvatar image={auth.userImage} userId={auth.userId}/>       
              </Grid> 
              <Grid item xs={12} align="center">
                <FormLabel id="row-radio-buttons-group-label">
                  <Typography color="Black" fontSize={24}>My TimeZone</Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="row-radio-buttons-group-label"
                  name="radio-buttons-group"
                  defaultValue={myoffset}
                  row
                  onChange={changeTimezone}
                >
                  <FormControlLabel value="PST" control={<Radio  />} label="PST" />
                  <FormControlLabel value="MST" control={<Radio  />} label="MST" />
                  <FormControlLabel value="CST" control={<Radio  />} label="CST" />
                  <FormControlLabel value="EST" control={<Radio  />} label="EST" />
                </RadioGroup>     
              </Grid>   
              <br/>
              <Grid item xs={12} align="center">
                {!showPassword && (
                  <Button variant="contained" color='secondary' onClick={() => setShowPassword(true)}>
                    Change Password
                  </Button>
                )}
                {showPassword && (
                  <ChangePassword userId={auth.userId} token={auth.token} refresh={passwordChangeHandler}/>
                )}
              </Grid>          
            </Paper>
          </Grid>
        </Grid>
           
        </React.Fragment>
        
  );
};

export default Settings;
