
const wagerInvited = "INVITED";
const wagerDeclined = "DECLINED";
const wagerCanceled = "CANCELED";
const wagerInplay = "IN PLAY";
const wagerValidate = "VALIDATE";
const wagerJudge = "JUDGE";
const wagerArbInitiated = "IN ARB";
//const wagerSnacksRewrite = "REWRITTEN";
const wagerRejected = "REJECTED";
const wagerContested = "CONTESTED";
const wagerArbitrated = "ARBITRATED";
const wagerSelfArbitrated = "SELFARBITRATED";
export {wagerInvited, wagerDeclined, wagerCanceled, wagerInplay, wagerValidate, wagerRejected, wagerJudge,  
        wagerArbInitiated, wagerContested, wagerArbitrated, wagerSelfArbitrated};