import React from 'react';

import TopicItem from './TopicItem';
import Card from '../../shared/components/UIElements/Card';
import './TopicsList.css';

const TopicsList = props => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No topics found.</h2>
        </Card>
      </div>
    );
  }

  return (
    <ul className="topics-list">
      {props.items.map(topic => (
        <TopicItem
          key={topic.id}
          id={topic.id}
          title={topic.title}
          area={topic.area}
        />
      ))}
    </ul>
  );
};

export default TopicsList;
