import React, { useEffect, useState, useContext} from 'react';
import {  useNavigate, Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import  {Avatar}  from '@mui/material';
import Typography from '@mui/material/Typography';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { BetContext } from '../../shared/context/bet-context';
import { AuthContext } from '../../shared/context/auth-context';
import { setDefaultEvent } from '../../shared/helpers/utilities';
import "../../admin/Form.css"
import AddWagerTitle from '../components/AddWagerTitle';

const increment = 5;
const BetTopic = () => {
  const bet = useContext(BetContext);
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedTopics, setLoadedTopics] = useState();
 
  const navigate = useNavigate();
  const givenName = `${auth.username} vs ${bet.opponentName}`;
  const [displayTopics, setDisplayTopics] = useState();
  const [topicSize, setTopicSize] = useState(increment);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        // Now get topics
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/topics/active`
        );
      
        const activeTopics = responseData.topics.filter( (topic) => {
          return topic.disabledToken!=="disable";
        });
        setLoadedTopics(activeTopics);
        setDisplayTopics(activeTopics.slice(0,increment));

      } catch (err) {}
    };
    fetchTopics();
  }, [sendRequest, givenName]);

  const loadMore = () =>{
    setTopicSize(increment+topicSize);
  }
  const loadLess = () =>{
    setTopicSize(increment);
  }
  useEffect(() => {
    if(loadedTopics){
      setShowMore(loadedTopics.length > topicSize);
      setShowLess(topicSize > increment)
      setDisplayTopics( loadedTopics.slice(0, topicSize) );
    }
    
  }, [topicSize, loadedTopics]); 

  const eventSubmitHandler = async (event, topicId) => {
    event.preventDefault();
    try {
      const myTopic = displayTopics.find((element) => { return element._id === topicId});

      if (myTopic.title.toLowerCase()!=="open"){
        bet.topicId = myTopic._id;
        bet.topicName = myTopic.title;
        bet.topicArea = myTopic.area;
        navigate(`/addbetevent/${bet.topicId}`);
      } else {
        const defaultTopic = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/topics/default`
        );
        bet.topicArea = defaultTopic.topic.area;
        bet.topicName = defaultTopic.topic.title;
        setDefaultEvent(defaultTopic,bet, auth.timezone);
        navigate(`/addbettext`); 
      }
      
  } catch (err) {}
  };


  return (
    <React.Fragment>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
        
      {!isLoading && displayTopics && (
       
        <React.Fragment>
          <AddWagerTitle />
          
          <Typography color="#FF1493" style={{ fontWeight: 900 }} variant="h6"><i>Topics</i></Typography>
          <List  sx={{ width: '100%', maxWidth: 500, minWidth:400, bgcolor: 'white'}}>
            {displayTopics.map(topic => (
                <ListItem alignItems="flex-start" key={topic._id} onClick={(event)=>eventSubmitHandler(event,topic._id) }>
                  <ListItemAvatar>
                    <Avatar  src={`/images/${topic.area.charAt(0) + topic.area.substring(1).toLowerCase()}Topic.png`} />
                  </ListItemAvatar>
                  <ListItemText><h6>{topic.title}</h6></ListItemText>
                </ListItem>
              ))}
          </List>
           
          {showMore && <Button sx={{width:120, float:"left"}} onClick={loadMore}>more topics </Button>}
          {showLess && <Button sx={{width:120, float:"right"}}onClick={loadLess}>less topics</Button>}
          <div width="100" align="center"><Link to="/"><Button>cancel</Button></Link></div>
          <br/>
        </React.Fragment>

      )}
      {error && (
        <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
      )}

    </React.Fragment>
  );
};

export default BetTopic;
