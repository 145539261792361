import React from 'react';

 

import dayjs from 'dayjs';

import AnnouncementItem from './AnnouncementItem';

import Card from '../../shared/components/UIElements/Card';

import './EventsList.css';

 

const AnnouncementsList = ({ items, removeUpdate}) => {

  if (items.length === 0) {

    return (

      <div className="center">

        <Card>
          <h2>No Announcements found.</h2>
        </Card>

      </div>

    );

  }


  return (

    <ul className="events-list">

      {items.map(announcement => (

        <AnnouncementItem
          key={announcement.id}
          id={announcement.id}
          message={announcement.message}
          creator={announcement.creator}
          createddate={processDate(announcement.createddate)}
          removeUpdate = {removeUpdate}
        />

      ))}

    </ul>

  );

};

 

export default AnnouncementsList;

function processDate(date) {

  return dayjs(date).format('MM/DD/YYYY HH:mm');

}

 