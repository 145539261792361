import React , {useEffect, useState} from 'react';

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

const AllTimeStats = ({wagers, player}) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedStats, setLoadedStats] = useState(false);
  const [winRank, setWinRank] = useState(0);
  const [winPercentRank, setWinPercentRank] = useState(0);
  const [playsRank, setPlaysRank] = useState(0);
  const h2hWagers = wagers.filter(wager => !wager.house);
  const plays = h2hWagers.filter(wager => wager.arbitrated).length;
  const wins = h2hWagers.filter(wager => wager.arbitrated && wager.arbitrationwinner===player.userId).length;
  const winPercent = Math.round(100*wins/plays).toFixed(1);

  useEffect(() => {
    const fetchStats = async () => {
      setLoadedStats(false);
      try {
        const stats = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/leaderboard/rankings?wins=${wins}&winpercent=${winPercent}&plays=${plays}`
        );
        setWinRank(stats.winrank);
        setWinPercentRank(stats.winpercentrank);
        setPlaysRank(stats.playsrank);
        setLoadedStats(true);
      } catch (err) {}
    }
    fetchStats();
    
  }, [sendRequest, wins, winPercent, plays]);

  return (
    <React.Fragment>  
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedStats && (
        <React.Fragment>  
          <Typography color="Black" fontSize={12} marginTop={1}>
            All time H2H stats <Typography color="grey" fontSize={12} display={'inline'}>(rank)</Typography>
          </Typography>

          <Grid container xs={12} sx={{backgroundColor: 'grey.200' ,textAlign:"center"}}>
            <Grid item xs={4}>
              <Typography color="Black" fontSize={14}>Plays  
              <Typography color="secondary" fontSize={12} display={'inline'}> {plays}</Typography> ({playsRank})</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="Black" fontSize={14}>Wins  
              <Typography color="secondary" fontSize={12} display={'inline'}> {wins}</Typography> ({winRank})</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="Black" fontSize={14}>Win%  
              <Typography color="secondary" fontSize={12} display={'inline'}> {winPercent}</Typography> ({winPercentRank})</Typography>
            </Grid>
          </Grid>
      </React.Fragment>  
      )}
    </React.Fragment>  
  );
};

export default AllTimeStats;
