import React , {useEffect, useState} from 'react';
import { Link }  from 'react-router-dom'

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Grid } from '@mui/material';

import Card from '../../shared/components/UIElements/Card';
import {gameLeaderboard} from '../../shared/helpers/game-formatters'

const GameLeaderboard = ({wagers}) => {

  const [opponentMap, setOpponentMap] = useState(new Map());
  
  useEffect(() => {
    setOpponentMap(gameLeaderboard(wagers));
    // eslint-disable-next-line
  }, [wagers]);

  if (opponentMap.size === 0) {
    return (
      <div className="center">
        <Card>
          <h4>No arbitrated wagers found.</h4>
        </Card>
      </div>
    );
  }
  
  return (
    <Grid container  style={{ minWidth: 300 }}>
       <Grid item xs={12}>
            <Paper>
              <Grid container xs={12} sx={{textAlign:"left"}}>
                
                <Grid item xs={4}> </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Wins</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Losses</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="grey" fontSize={12}>Win %</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Paper>
        </Grid>
        <Divider width = '100%'></Divider>
        
      {[...opponentMap].map(([name, stats]) => {
        return (
          <React.Fragment>
            <Grid item xs={4}>
                <Typography color="secondary" fontSize={24}>
                  <Link  color="secondary" to={`/profile/${name}`} ><b>{name}{stats.suffix}</b></Link>
                </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color="grey" fontSize={24}>{stats.wins}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color="grey" fontSize={24}>{stats.losses}</Typography>  
            </Grid>
            <Grid item xs={2}>
              <Typography color="Green" fontSize={24}>{stats.winpercent}%</Typography>
            </Grid>
          </React.Fragment>
        )
        })
      }
    </Grid>
  );
};

export default GameLeaderboard;
