import React, { useRef, useEffect, useContext, useState } from 'react';

import Button from '@mui/material/Button';

import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import './CasualWagerAvatar.css';

const CasualWagerAvatar = ({image, userId}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [file, setFile] = useState();
  const auth = useContext(AuthContext);
  const [previewUrl, setPreviewUrl] = useState(image);
  const [isValid, setIsValid] = useState(false);
  const [errorText, setErrorText] = useState();

  const filePickerRef = useRef();
  const canOpen = auth.userId === userId;
  
  useEffect(() => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      if(fileReader.result.length > 150000){
        setIsValid(false);
        setErrorText("File too large. Please Pick a file smaller than 150k");
      } else {
        setErrorText("");
        setIsValid(true);
        setPreviewUrl(fileReader.result);
      }
    };
    fileReader.readAsDataURL(file);
  }, [file]); 

  const pickedHandler = event => {
    let pickedFile;
    if (event.target.files && event.target.files.length >0) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
    } else {
      setIsValid(false);
      setErrorText("Please Pick a file less than 100k");
    }
  };

  const imageUploadHandler = async event => {
    try {
      var outstring = JSON.stringify({
        id: `${auth.userId}`,
        image: `${previewUrl}`
      });
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/newimage`,
        'POST', outstring,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      auth.setUserImage(previewUrl);
    } catch (err) {
     setErrorText(err.message);
     setIsValid(false);
    }
  }

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading  && (
        <React.Fragment>
          <div >
            <input
              id="image"
              ref={filePickerRef}
              style={{ display: 'none' }}
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={pickedHandler}
            />
            <div className={`image-upload`}>
              <div className="image-upload__preview">
                {previewUrl && <img src={previewUrl} alt="Preview" />}
                {!previewUrl && <p>Please pick an image.</p>}
              </div>
              {canOpen && <Button variant="contained" onClick={pickImageHandler}>New Image</Button>}
              {"   "}
              {isValid && <Button variant="contained" onClick={imageUploadHandler}>Upload</Button>}
            </div>
            {!isValid && <p className="error-text">{errorText}</p>}
          </div>
        </React.Fragment>
      )}

    </React.Fragment>
  );
};

export default CasualWagerAvatar;
