import React from 'react';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import { useHttpClient } from '../../shared/hooks/http-hook';
import './TestPanel.css';

const TestPanel = () => {

  const {isLoading, error} = useHttpClient();

  return (
    <React.Fragment>
      <Card className="testpanel">
        {isLoading && <LoadingSpinner asOverlay />}
        <h4>Width Test Panel Casual Wager</h4>
        <hr />
        <form >
          <input
            element="input"
            id="name"
            type="text"
            label="Your Username"
            errorText="Please enter a username."

          />
          <input
            element="input"
            id="email"
            type="email"
            label="E-Mail"

            errorText="Please enter a valid email address."

          />
          <input
            element="input"
            id="password"
            type="password"
            label="Password"

            errorText="Please enter a valid password, at least 6 characters."

          />
          <Link to="/"><Button variant="contained">Cancel</Button></Link>
        </form>
        {error && (
          <Alert severity="error"><AlertTitle>Registration Error</AlertTitle>{error}</Alert>
        )}
      </Card>
    </React.Fragment>
  );
};

export default TestPanel;
