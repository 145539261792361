import React, { useState, useContext } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import Home from './user/pages/Home';
import About from './user/pages/About'
import Welcome from './user/pages/Welcome';
import Profile from './user/pages/Profile';
import Settings from './user/pages/Settings';
import UnderConstruction from './user/pages/UnderConstruction';
import Admin from './admin/Admin';
import Users from './admin/Users';

import AddBetUser from './user/pages/AddBetUser';
import AddBetGame from './user/pages/AddBetGame';
import AddBetGameType from './user/pages/AddBetGameType';
import AddBetTopic from './user/pages/AddBetTopic';
import AddBetEvent from './user/pages/AddBetEvent';
import AddBetText from './user/pages/AddBetText';
import AddBetPreview from './user/pages/AddBetPreview';
import PeoplePage from './user/pages/PeoplePage';
import LeaderboardPage from './user/pages/LeaderboardPage';

import ViewGame from './user/components/GameDetail';
import ViewBet from './user/components/BetDetail';
import GameRules from './user/components/GameRules'
import GameTrophy from './user/components/GameTrophy'

import Topics from './admin/AllTopics';
import Topic from './admin/Topic';

import Event from './admin/Event';
import EventRemove from './admin/RemoveEvent';
import Events from './admin/AllEvents';
import AddEvent from './admin/AddEvent';
import Announcements from './admin/Announcements';

import Trophies from './admin/AllTrophies';
import AddTrophy from './admin/AddTrophy';

import EventArbitration from './admin/EventArbitration';
import SnacksArbitration from './admin/SnacksArbitration';
import SnacksValidation from './admin/SnacksValidation';
import EventArbitrationList from './admin/EventArbitrationList';
import WagerArbitrationList from './admin/WagerArbitrationList';
import ArbitrateItem from './admin/ArbitrateItem';
import RewriteItem from './admin/RewriteItem';

import Register from './user/pages/Register';
import TestPanel from './user/pages/TestPanel';
import RequestPassword from './user/pages/RequestPassword';
import ResetPassword from './user/pages/ResetPassword';
import InvitedPlayer from './user/pages/InvitedPlayer';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import { DialogContent } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette:{
    primary:{
      main: '#0094ff' //blue
    },
    secondary:{
      main: '#ff00dc' //pink
    }
  }

})

const App = () => {

  const { token, login, logout, userId, userRole, userName, userImage, dbStatus, hasSeenAnnouncement, playedSince, 
    setUserImage, changeDBStatus, timezone, setTimezone } = useAuth();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  TimeAgo.setDefaultLocale(en.locale)
  TimeAgo.addLocale(en) 

  const setDialogToOpen = () =>{
    setOpen(true);
  }

  let routes=open;
  
  if (token){
    routes = (
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/profile" element={<Profile />}/>
        <Route path="/profile/:username" element={<Profile />}/>
        <Route path="/settings" element={<Settings />}/>

        <Route path="/admin" element={<Admin />}/>
        <Route path="/admin/announcements" element={<Announcements />}/>
        <Route path="/admin/users" element={<Users />}/>
        <Route path="/admin/events" element={<Events />}/>
        <Route path="/admin/events/:eventId" element={<Event />}/>
        <Route path="/admin/trophies" element={<Trophies />}/>
        <Route path="/admin/addtrophy" element={<AddTrophy />}/>
        <Route path="/admin/eventsbets/:eventId" element={<EventArbitrationList />}/>
        <Route path="/admin/arbitratewagers" element={<WagerArbitrationList />}/>
        <Route path="/admin/eventremove/:eventId" element={<EventRemove />}/>
        <Route path="/admin/addevent/:topicId" element={<AddEvent />}/>
        <Route path="/admin/arbitratebet/:betId" 
              element={ 
                <Dialog open={() => { setOpen(true); return open;}} onClose={() => { setOpen(false);}}>
                  <DialogTitle>Arbitration</DialogTitle>
                  <ArbitrateItem />
                </Dialog>
              }
        />
        <Route path="/admin/rewritebet/:betId" 
              element={ 
                <Dialog open={() => { setOpen(true); return open;}} onClose={() => { setOpen(false);}}>
                  <DialogTitle>Snacks Rewrite</DialogTitle>
                  <RewriteItem />
                </Dialog>
              }
        />
        <Route path="/admin/arbitration" element={<EventArbitration />}/>
        <Route path="/admin/snacksarbitration" element={<SnacksArbitration />}/>
        <Route path="/admin/snacksvalidation" element={<SnacksValidation />}/>
        <Route path="/admin/topics" element={<Topics />}/>
        <Route path="/admin/topics/:topicId" element={<Topic />}/>

        <Route path="/underconstruction" element={<UnderConstruction />}/>
        <Route path="/about" element={<About />}/>

        <Route path="/addbetuser" 
              element={ 
                <Dialog
                  open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  {
                    //<DialogTitle align={"center"}>Create Wager</DialogTitle>
                  }
                  <DialogContent  style={{height:'900'}}>
                    <AddBetUser />
                  </DialogContent>
                </Dialog>
              }
        />
        <Route path="/addbetgame" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <DialogTitle>Name Your Game</DialogTitle>
                  <AddBetGame />
                </Dialog>
              }
        />
        <Route path="/addbetgametype" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <DialogTitle>Set Game Type</DialogTitle>
                  <AddBetGameType />
                </Dialog>
              }
        />
        <Route path="/addbettopic" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <DialogTitle align={"center"}>Create Wager</DialogTitle>
                  <AddBetTopic />
                </Dialog>
              }
        />
        <Route path="/addbetevent/:topicId" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <DialogTitle align={"center"}>Create Wager</DialogTitle>
                  <AddBetEvent />
                </Dialog>
              }
        />
        <Route path="/addbettext" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  {
                    //<DialogTitle align={"center"}>Create Wager</DialogTitle>
                  }
                  <AddBetText />
                </Dialog>
                  }
        />
        <Route path="/addbetpreview" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <AddBetPreview />
                </Dialog>
              }
        />
        <Route path="/gamerules/:gamename" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <DialogTitle>Game Rules</DialogTitle>
                  <GameRules />
                </Dialog>
              }
        />
        <Route path="/gametrophy/:gamename/:trophyid" 
              element={ 
                <Dialog open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                  <DialogTitle>Game Trophy</DialogTitle>
                  <GameTrophy />
                </Dialog>
              }
        />

      <Route path="/viewgame/:gameId" element={<ViewGame />}/>
      <Route path="/viewbet/:betId" element={<ViewBet />}/>
      
      <Route path="/peoplepage" 
             element={
              <Dialog maxWidth="sm" open={setDialogToOpen} onClose={() => { setOpen(false);}}>
                <DialogTitle>Player Search</DialogTitle>
                <PeoplePage />
              </Dialog>
              }
      />
      <Route path="/leaderboard" element={<LeaderboardPage />}/>
      <Route path="*" element={<Navigate to="/" />} />
    
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Welcome />}/>
        <Route path="/register" element={<Register />}/>
        <Route path="/testpanel" element={<TestPanel />}/>
        <Route path="/requestpassword" element={<RequestPassword />}/>
        <Route path="/resetpassword/:id" element={<ResetPassword />}/>
        <Route path="/InvitedPlayer/:id" element={<InvitedPlayer />}/>
        <Route path="/underconstruction" element={<UnderConstruction />}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }
  

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          dbStatus: dbStatus,
          token: token,
          userId: userId,
          username: userName,
          role: userRole,
          userImage: userImage,
          hasSeenAnnouncement: hasSeenAnnouncement,
          timezone: timezone,
          playedSince: playedSince,
          setTimezone: setTimezone,
          changeDBStatus: changeDBStatus,
          setUserImage: setUserImage,
          login: login,
          logout: logout
        }}
      >
        <Router>
          <MainNavigation item={auth}/>
          <main width="90%">{routes}</main>
        </Router>
      </AuthContext.Provider>
  </ThemeProvider>
  );
};

export default App;
