import React, { useContext }  from 'react';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL
} from '../../shared/helpers/validators';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useForm } from '../../shared/hooks/form-hook';
import { AuthContext } from '../../shared/context/auth-context';

import './Welcome.css';

const Welcome = () => {

  const auth = useContext(AuthContext);
  const { isLoading, error, newError, sendRequest } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    }
  );

  const authSubmitHandler = async event => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/login`,
        'POST',
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value
        }),
        {
          'Origin':'casualwager.com',
          'Content-Type': 'application/json'
        }
      );

      auth.login(responseData.player, 
        responseData.token);
      } catch (err) { 
        newError(err.message);
      }
  };

  return (
    <React.Fragment>
          
      {isLoading && (
        <div className="boo center">
          <LoadingSpinner />
        </div>
      )}
      {(
        <React.Fragment>

          <Card className="authentication z-depth-0">
            <>
              <h4>This is <b> Casual Wager</b><br/> the game where you track wagers and predictions with friends.</h4>
            </>
            <Link to="/register">
              <Button variant="contained">Make New Account</Button>
            </Link>
         
          </Card>

          <Card className="authentication">
            <h4>Welcome back!</h4>
           
            <form onSubmit={authSubmitHandler}>
              <Input
                element="input"
                id="email"
                type="email"
                label="E-Mail"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address."
                onInput={inputHandler}
              />
              
              <Input
                element="input"
                id="password"
                type="password"
                label="Password"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Please enter a valid password, at least 6 characters."
                onInput={inputHandler}
              />
              <Button variant="contained" type="submit" disabled={!formState.isValid}>
                Log in
              </Button>
              <h6>If you forgot your password you can{"  "}
                <Link to="/requestpassword">
                  request a new one here.
                </Link>
              </h6>
            </form>
            {error && (
              <Alert severity="error"><AlertTitle>Login Error</AlertTitle>{error}</Alert>
            )}
        </Card>

        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Welcome;
