import React from 'react';

import dayjs from 'dayjs';
import UnarbitratedEventItem from './UnarbitratedEventItem';
import Card from '../../shared/components/UIElements/Card';
import './EventsList.css';

const UnarbitratedEventsList = props => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No Unarbitrated Events found.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <h3>Unarbitrated Events</h3>
      <ul className="events-list">
        {props.items.map(event => (
          <UnarbitratedEventItem
            key={event.id}
            id={event.id}
            topic={event.topic.title}
            name={event.name}
            closedate={processDate(event.wagerCloseDate)}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};

function processDate(date) {
  return dayjs(date).format('MM/DD/YYYY');
}

export default UnarbitratedEventsList;


