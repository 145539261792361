import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './EventItem.css';

const EventItem = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const [betCount, setBetCount] = useState();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/event/${props.id}`
        );
        setBetCount(responseData.bets.length);
      } catch (err) {}
    };
    fetchEvents();
  }, [sendRequest, props.id]);

  
  return (
    <React.Fragment>
      { !isLoading && (
      <li className="event-item">
            <div className="event-item__info">
              <h2>
                <b>{props.name} </b> <br/>
                wager close date: {props.closedate}<br/>
                event start date: {props.startdate} <br/>
                event end date: {props.enddate} <br/>
                arbitrated?: {props.arbitrated? "Yes":"No"}<br/>
                number of wagers: {betCount}
              </h2>
              <Link to={`/admin/events/${props.id}`}><Button variant="contained">View Event</Button></Link>
              {!betCount && (
               <React.Fragment>
                 <hr/>
                 <Link to={`/admin/eventremove/${props.id}`}><Button variant="contained">Remove Event</Button></Link>
                </React.Fragment>
              )}
            </div>
      </li>
      )}
    </React.Fragment>
  );
};

export default EventItem;
