import React from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import BetHeader from './BetHeader';
import BetFooter from './BetFooter';

import {wagerInvited} from '../../wagerStates'

import './BetStyles.css';


const BetItem = props => {
  const itemColor = "bet-item bet-item-color-" + resolveStatus();
  
  
  function resolveStatus() {
    let results = "invite";

    if(props.arbitrated){
      results = "arbd";
    } else {
      if(props.status !== wagerInvited){
        results = "inplay";
      }
    }
   
    return results;
  }
  
  return (
    <React.Fragment>
      <Card 
        square={true}
        elevation={0} 
        className={itemColor}
      >
        <BetHeader
          id={props.id}
          wager={props.wager}
          canwager={props.canwager}
          topicTitle={props.topicTitle}
          topicArea={props.topicArea}
          event={props.event}
          text={props.text}
          status={props.status}
          creatorname={props.creatorname}
          creatorimage={props.creatorimage}
          opponentname={props.opponentname}
          opponentimage={props.opponentimage}
          arbitrated={props.arbitrated}
          arbitrationPosition={props.arbitrationPosition}
          
        />

        <BetFooter 
          id={props.id}
          comments={props.wager.comments}
        />

      </Card>

      <Grid container sx={{maxWidth: 300}} 
          alignItems="left"
          justifyContent="left">
        <Grid item xs={12}>

        
        </Grid>
      </Grid>    
      </React.Fragment>
  );

};

export default BetItem;