import React from "react";
import { Link } from 'react-router-dom';

import Linkify from 'react-linkify';
// eslint-disable-next-line 
import dayjs from 'dayjs';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {timeFormatter} from "../helpers/formatters";
function Comment(props) {
  const { creatorname, message, createddate } = props.comment;

  return (
    <ListItem>
      <ListItemText
        primary={ 
          <React.Fragment>
            <Link to={`/profile/${creatorname}`} > <b>{creatorname}:</b></Link>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
              )}>{message}  [{timeFormatter(createddate)} ago]
            </Linkify>
 
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default Comment;