import React, { useEffect, useState } from 'react';

import TopicsList from './components/TopicsList';
import ErrorModal from '../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';

const Topics = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedTopics, setLoadedTopics] = useState();

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/topics`
        );

        setLoadedTopics(responseData.topics);
      } catch (err) {}
    };
    fetchTopics();
  }, [sendRequest]);


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
       {!isLoading && loadedTopics && <TopicsList items={loadedTopics} />}
    </React.Fragment>
  );
};

export default Topics;
