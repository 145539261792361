import React , { useContext, useState }from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import { red } from '@mui/material/colors'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { BetContext } from '../../shared/context/bet-context';


const AddWagerTeamSports = props => {
  const bet = useContext(BetContext);
  const [radioValue, setRadioValue] = useState("over");
  const [winningTeam, setWinningTeam] = useState(bet.eventDetails.teams.home.name);
  const isPoints = (props.form==="points");

  const radioChangeHandler = (event) => {
    setRadioValue (event.target.value);  
  }

  const inputHandler = (event) => {
    let validInput = event.target.value.match(/^[0-9,.]*$/);
    validInput = validInput && event.target.value > 0;
    if(validInput) {
      var text = "";
      if(isPoints){
        text = `${winningTeam} will win by ${event.target.value} or more`;
      } else {
        text = `${bet.eventDetails.teams.home.name} vs ${bet.eventDetails.teams.away.name} total score will be ${radioValue} ${event.target.value}`;
      }
      bet.betText = text;
      props.inputHandler(false);
      return true;
    } else {
      alert("Please enter a valid number");
      props.inputHandler(true);
      return false;
    }
    
  }

  return (

       <React.Fragment>
  
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid  item xs={2.5}>
              <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              >
                <Typography color="black" style={{  align: "left", fontWeight: 'bold', paddingRight:'10px' }}>
                  <div align="center" onClick={() => setWinningTeam(bet.eventDetails.teams.home.name)}>
                    <Avatar 
                      alt={bet.eventDetails.teams.home.name}
                      src={bet.eventDetails.teams.home.logo}
                      sx={{ bgcolor: red[500] }} aria-label="recipe" />
                
                    <h6>{bet.eventDetails.teams.home.name}</h6>
                  </div>
                </Typography>

                <Typography color="primary">
                  <Button variant="text"><br/>VS <br/><br/></Button>
                </Typography>

                <Typography color="black" style={{  align: "right", fontWeight: 'bold', paddingLeft:'10px'}}>
                  <div align="center" onClick={() => setWinningTeam(bet.eventDetails.teams.away.name)}>
                    <Avatar 
                      alt={bet.eventDetails.teams.away.name}
                      src={bet.eventDetails.teams.away.logo}
                      sx={{ bgcolor: red[500] }} aria-label="recipe"
                    />
                    <h6>{bet.eventDetails.teams.away.name}</h6>
                  </div>
                </Typography>               
              </Grid>                  
            </Grid>   
          </Grid>

          {isPoints && (
            <Typography variant="h6" color="black" style={{  align: "right", fontWeight: 'bold', paddingLeft:'25px'}}>
            <div align="center">
              Click Team Logo to change the winner<br/>
              {winningTeam} will win by<br/>               
                <input placeholder='0' style={{height:20, width:40}} onChange={inputHandler} /><br/>
                or more
            </div>
          </Typography>
          )}

          {!isPoints && (
            <Typography variant="h5" color="black" style={{  align: "right", fontWeight: 'bold', paddingLeft:'25px'}}>
              <div align="center">
                total score will be 
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue="over" 
                    onChange={radioChangeHandler}
                    style={{  fontWeight: 'bold'}}
                  >
                    <FormControlLabel value="over" control={<Radio />} label="Over" 
                      sx={{  color: 'black', fontWeight: 'bold'}}/>
                    <FormControlLabel value="under" control={<Radio />} label="Under" 
                      sx={{  color: 'black', fontWeight: 'bold'}}/>
                  </RadioGroup>
                </FormControl>
                <input placeholder='0' style={{height:20, width:40}} onChange={inputHandler} />
              </div>
            </Typography>
          )}
      </React.Fragment>

  );

  
  
};

export default AddWagerTeamSports;
