import React from "react";

import List from '@mui/material/List';

import Comment from "./Comment";

function CommentList(props) {
  return (
    <React.Fragment>
      {(props.comments === undefined || props.comments.length === 0) && !props.loading && (
        <div className="alert text-center alert-info">
          Be the first to comment
        </div>
      )}

      {props.comments !== undefined && props.comments.length>0 && !props.loading  && (
        <List sx={{ width: '95%',   height: '300', bgcolor: 'background.paper' }}>
            {props.comments.map( (comment, index) => {
              return(
                <Comment key={index} comment={comment} width="0"/>
              )
            })}
        </List>
      )}
    </React.Fragment>
  );
};

export default CommentList;