import React , {useEffect, useState} from 'react';
import { Link }  from 'react-router-dom'
import dayjs from 'dayjs';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

import {  commentTruncator} from '../../shared/helpers/formatters'

const PlayerHistory = ({wagers, player}) => {

  const [displayWagers, setDisplayWagers] = useState([]);

  useEffect(() => {
    const myUsername = player.username;
    const myId = player.userId;
    const newDisplayWagers = [];

    for(let i=0; i< wagers.length; i++){
      const wager = wagers[i];
      
      let myOpponent;
      if(wager.game.house){
        myOpponent = wager.game.name;
      } else {
        myOpponent = "v " + wager.opponent.username;
        if(myOpponent===myUsername){
          myOpponent = "v " + wager.creator.username;
        }
      }
      
      
      let status = wager.status; 
      let color = "blue"
      let detailDate = dayjs(wager.event.wagerCloseDate).format('YYYY MMM DD')
      if(wager.arbitrated){
        detailDate = dayjs(wager.arbitrationdate).format('YYYY MMM DD')
        let results=wager.arbitrationwinner=== myId?"won":"lost";
        results = wager.arbitrationwinner===undefined?"house":results;
        if (results==="lost"){
          color = "red"
        } else {
          color = "green"
        }
        status = results.toUpperCase()
      } 

      newDisplayWagers.push({
        opponent: myOpponent,
        event: wager.event.name,
        text: wager.text,
        date: detailDate,
        status: status,
        color: color,
        game: wager.game._id,
        id: wager._id
      });
    }

    setDisplayWagers(newDisplayWagers);
    // eslint-disable-next-line
  }, [wagers]);

  if (wagers.length === 0) {
    return (
      <div className="center">

      </div>
    );
  }
  
  return (
    <Grid container  style={{ minWidth: 300 }}>
      <Typography color="black" fontSize={20} sx={{marginTop:"20px"}}>
        <b>HISTORY</b>
      </Typography>
   
      {[...displayWagers].map((aWager) => {
        return (
          <React.Fragment>
            <Grid item xs={12}>
            <Paper>

              <Grid container xs={12} sx={{textAlign:"left"}}>
                <Grid item xs={11}>                 
                  <Typography color="black" fontSize={14}><b>{aWager.date}</b>
                    <Link to={`/viewgame/${aWager.game}`}>
                      <Typography color="secondary" fontSize={14} display={'inline'}> {aWager.opponent}</Typography>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{textAlign:"right"}}>
                  <Typography color={aWager.color} fontSize={14}>{aWager.status}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="grey" fontSize={18}>{aWager.event} - 
                  <Typography fontSize={18} display={'inline'}>
                    <Link color="black" to={`/viewbet/${aWager.id}`}>{commentTruncator(70,aWager.text)}</Link>
                  </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Divider width = '100%'></Divider> 
        </React.Fragment>
        )
        })
      }
    </Grid>
  );
};

export default PlayerHistory;
