import React, {  useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';


import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';
import CommentList from '../../shared/components/CommentList';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useSSEEvent } from '../../shared/hooks/sse-hook';

import "./GameComments.css";

//comments on the game (or wager) detail page

const commentsIncrement = 5;

const BetComments = (props) => {
    const { isLoading,  sendRequest, error } = useHttpClient();
    const auth=useContext(AuthContext);
    const betId = useParams().betId;
    const {startSSE, stopSSE, setCommentChange} = useSSEEvent();
    
    const [commentsSize, setCommentsSize] = useState(commentsIncrement);
    const [betComments, setBetComments] = useState(props.comments);
    const [displayComments, setDisplayComments] = useState(props.comments.slice(0,commentsIncrement));
    const [showMore,setShowMore] = useState(props.comments.length > commentsIncrement);
    const [showLess,setShowLess] = useState(false);

    let message = "";
    
    const handleMessageChange = event =>{
        message = event.target.value;
    }

    useEffect(() => {
      return () => {
        stopSSE("betcomments");
      }
      // eslint-disable-next-line
    }, [])

    startSSE();
    setCommentChange(betId,onCommentChange);

    function onCommentChange(){
      loadComments();
    }

    async function  loadComments(){
      const betData = await sendRequest(
        `${process.env.REACT_APP_API_HOST}/bets/${betId}`
      );
      setBetComments( betData.bet.comments );
    }

    const eventSubmitHandler = async event => {
        event.preventDefault();
        try {
          if(message){
            
            var outstring = JSON.stringify({
              betid: `${betId}`,
              creator: `${auth.userId}`,
              message: `${message}`
            });
            await sendRequest(
              `${process.env.REACT_APP_API_HOST}/bets/addcomment`,
              'POST',
              `${outstring}`,
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
              }
            );

           loadComments();
          }
        } catch (err) {}
      };

      // reset the displayed comments when comments or size changes
      useEffect(() => {
        setShowMore(betComments.length > commentsSize);
        setShowLess(commentsSize > commentsIncrement)
        setDisplayComments( betComments.slice(0,commentsSize) );
      }, [commentsSize, betComments]); 
      const loadMore = () =>{
        setCommentsSize(commentsIncrement+commentsSize);
      }
      const loadLess = () =>{
        setCommentsSize(commentsIncrement);
      }

       if(isLoading) {
         return <LoadingSpinner />
       } else {
    
        return (
          <React.Fragment>

            <Grid container style={{ maxWidth: 550 }}>
              <Grid item xs={10}>
              <TextField
                id="message"
                element="input"
                type="text"
                name="message"
                placeholder="I'm gonna totally beat you..."
                multiline
                variant="standard"
                fullWidth="true"
                onChange={handleMessageChange}
              />
              </Grid>
              <Grid item xs={2}>
                <Button sx={{marginLeft:'10px'}} disableElevation variant="contained" onClick={eventSubmitHandler}>Post</Button>   
              </Grid>
            </Grid>

            {error && (
              <Alert severity="error"><AlertTitle>Error</AlertTitle>{error}</Alert>
            )}

            <CommentList comments={displayComments} />
            {showMore && <Button sx={{float: 'left'}} variant="contained" onClick={loadMore}> Show More </Button>}
            {showLess && <Button sx={{float: 'right'}} variant="contained" onClick={loadLess}> Show Less </Button>}
          </React.Fragment>
          )
        }
}

export default BetComments;