import React, {useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import UserItem from './UserItemTable';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Input from '../../shared/components/FormElements/Input';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import {VALIDATOR_EMAIL} from '../../shared/helpers/validators';
import { AuthContext } from '../../shared/context/auth-context';

import './UsersList.css';

const UsersList = props => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const {  sendRequest } = useHttpClient();
  
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: '',
        isValid: false
      }
    }
  );
  const validEmail = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(props.email);
  

  const notifyNewUser = async event => {
    event.preventDefault();
    try {
      const outstring = JSON.stringify({
                            inviteemail: formState.inputs.email.value,
                            sender: `${auth.username}`,
                            host: `${process.env.REACT_APP_HOST}`
                          });
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/createinviteuser`,
        'POST',
        `${outstring}`,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      navigate('/');
    } catch (err) {}
  }

  if (props.items.length === 0 && validEmail!==undefined) {
    return (
      <Grid container style={{ minWidth: 350 }}>
        <Grid item xs={7}>
          <h5>No Users Found</h5>
        </Grid>
        <Grid item xs={5}>
          <Link to="/"><Button variant="text" style={{ float: 'right'}}>Done</Button></Link>
        </Grid>
        <Grid item xs={12}>
          <h5>Enter the email address of a friend to invite them to Casual Wager</h5>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={notifyNewUser}>
            <Input
                element="input"
                id="email"
                type="email"
                label="E-Mail"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address."
                initialValue={props.email}
                onInput={inputHandler}
                isValid={validEmail}
                isTouched={true}
              />
              <Button variant="contained" type="submit" disabled={!formState.isValid}>
                Send Invite
              </Button>
            </form>
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>  
      <Link to="/"><Button variant="text" style={{ float: 'right'}}>Done</Button></Link>
      <ul className="users-list">
        {props.items.map(user => (
          <UserItem
            key={user.id}
            user={user}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};

export default UsersList;
